import { SuccessMark } from "@app/components/Icon/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import AuthForm from "../components/AuthForm";

const ResetSuccessful = () => {
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/auth/signin");
  };

  return (
    <AuthForm
      title="Your password has been reset successfully."
      text="Login"
      description="You can now proceed to access the Admin Dashboard."
      handleSubmit={handleSubmit}
      icon={SuccessMark}
    ></AuthForm>
    // <div>hello</div>
  );
};

export default ResetSuccessful;
