import * as React from "react";
const SvgDelete = (props) => (
  <svg
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.91 4.834v7.273H3.09V4.834h5.818ZM7.817.471H4.182l-.727.727H.909v1.455h10.182V1.198H8.546L7.818.471Zm2.546 2.909H1.636v8.727c0 .8.655 1.455 1.455 1.455h5.818c.8 0 1.455-.655 1.455-1.455V3.38Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgDelete;
