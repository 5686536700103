import Form from "@app/components/common/Form";
import Input from "@app/components/common/Input";
import DatePicker from "@app/components/DatePicker";
import Select from "@app/components/Select";
import ThumbnailInput from "@app/components/ThumbnailInput";
import classNames from "classnames";
import { useFormik } from "formik";
// import { generateUrlForPoster } from "@app/helpers";
import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useErrorHandler } from "@app/utils/hooks";
import { useAddEpisodeToMovie } from "@app/utils/hooks/reactQuery/movie";
import { toast } from "react-toastify";
import Toast from "@app/components/Toast";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "@app/config/firebase.config";
import UploadController from "@app/components/UploadController";
const EPISODE_INFORMATION_FIELDS = [
  {
    info_type: "Title",
    placeholder: "",
    name: "title",
  },
  {
    info_type: "Episode Number",
    placeholder: "10",
    name: "episodeNumber",
  },
  {
    info_type: "Season",
    placeholder: "2",
    name: "season",
  },
  {
    info_type: "Episode Link",
    placeholder: "",
    name: "link",
  },
  {
    info_type: "Episode Preview Link",
    placeholder: "",
    name: "previewLink",
  },
  {
    info_type: "Description",
    placeholder: "placedholllder",
    name: "description",
  },
  {
    info_type: "Runtime in seconds",
    placeholder: 3600,
    name: "length",
  },
  // {
  //   info_type: "Date Released",
  //   placeholder: new Date().getFullYear(),
  //   name: "dateReleased",
  // },
  // {
  //   info_type: "Genre",
  //   placeholder: "comedy",
  //   name: "genre",
  // },
  {
    info_type: "Movie Rating",
    placeholder: "18",
    name: "viewRating",
  },
];

const RELEASE_STATUS = [
  {
    name: "Released",
    value: "released",
  },
  {
    name: "Coming Soon",
    value: "comingSoon",
  },
];

const validationSchema = Yup.object({
  yearOfRelease: Yup.string()
    .trim()
    .matches(/^[0-9]{4}$/, "Year of release isn't a valid year!"),
  viewRating: Yup.string().matches(
    /^[0-9]{2}$/,
    "Movie view rating isn't valid!"
  ),
});

const convertFromMilli = (number) => Math.floor(number / 1000);
const AddEpisodeForm = () => {
  const {
    isLoading,
    mutate,
    data: addEpisodeToMovieResponse,
  } = useErrorHandler(useAddEpisodeToMovie);
  const { seriesId } = useParams();
  const [isUploadingPoster, setIsUploadingPoster] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState(RELEASE_STATUS[0]);
  const [showError, setShowError] = useState(false);
  const formik = useFormik({
    initialValues: {
      title: "",
      season: "",
      episodeNumber: "",
      description: "",
      length: "",
      link: "",
      previewLink: "",
      viewRating: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      let payload = {
        ...values,
        length: parseInt(values.length),
        season: parseInt(values.season),
        episodeNumber: parseInt(values.episodeNumber),
        viewRating: `${parseInt(values.viewRating)}+`,
        status: status.value,
        dateReleased: convertFromMilli(new Date(date).getTime()),
      };
      // ====================================

      if (!files[0]) {
        setShowError(true);
      }

      if (files[0]) {
        setShowError(false);
        setIsUploadingPoster(true);
        const uploadTask = uploadBytesResumable(
          ref(
            storage,
            `movie_thumbnails/season_${values.season}_episode_${values.episodeNumber}_${files[0]?.name}`
          ),
          files[0]
        );
        // setUploadObject(uploadTask);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log("Upload is " + progress + "% done");
            // console.log({ snapshot });
            // setUploadState(snapshot.state);
            setUploadProgress(progress);
          },
          (error) => {
            mutate({ seriesId, episodes: [payload] });
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setIsUploadingPoster(false);
              payload = { ...payload, poster: downloadURL };
              mutate({
                seriesId,
                episodes: [payload],
              });
            });
          }
        );

        // mutate(payload);
      }

      // ==========================
    },
  });

  useEffect(() => {
    if (!isLoading && addEpisodeToMovieResponse) {
      toast.success(
        <Toast
          message="Episode added to movie successfully!"
          toastType="success"
        />
      );
      navigate("/movies", { replace: true });
    }
  }, [isLoading, addEpisodeToMovieResponse, navigate]);

  return (
    <Form
      showIcon={false}
      handleSubmit={formik.handleSubmit}
      buttonText="Add Episode"
      isLoading={isUploadingPoster || isLoading}
      formClassName="mb-[300px]"
    >
      {EPISODE_INFORMATION_FIELDS.map((item, index) => {
        return (
          <Input
            labelTitle={item.info_type}
            name={item.name}
            value={formik.values[item.name]}
            placeholder={item.placeholder || "placeholder"}
            showIcon={item.name === "password"}
            variant="transparent"
            key={index}
            message={
              formik.touched[item.name] &&
              formik.errors[item.name] && {
                type: "error",
                value: formik.errors[item.name],
              }
            }
            textarea={item.name === "description"}
            onChange={formik.handleChange}
            className={classNames({
              "w-[60%] max-w-[300px]":
                item.name === "viewRating" ||
                item.name === "yearOfRelease" ||
                item.name === "movieLength" ||
                item.name === "season" ||
                item.name === "episodeNumber",
            })}
          />
        );
      })}

      <DatePicker date={date} handleDateSelect={setDate} />

      <Select
        labeflText="Release Status"
        handleSelectedItem={setStatus}
        items={RELEASE_STATUS}
        selectedItem={status}
        className="w-[60%] max-w-[300px]"
      />

      <ThumbnailInput
        files={files}
        setFiles={setFiles}
        showError={showError}
        postThumbnail={
          <UploadController
            progress={uploadProgress}
            showButtons={false}
            size="small"
          />
        }
      />
    </Form>
  );
};

export default AddEpisodeForm;
