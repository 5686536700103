import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage, ref, listAll } from "firebase/storage";

// const storage = getStorage();
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAP2bneY2u4o5OP_3Ov-cAGAsIkXGuR7Jk",
  authDomain: "waptv-49035.firebaseapp.com",
  projectId: "waptv-49035",
  storageBucket: "waptv-49035.appspot.com",
  messagingSenderId: "508169046001",
  appId: "1:508169046001:web:62879af890912cff892650",
  measurementId: "G-TRFBTLJPNC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
export { auth, storage, listAll, ref };
//   const analytics = getAnalytics(app);
