import Button from "@app/components/common/Button";
import Layout from "@app/views/Authentication/Layout";
import classNames from "classnames";
import { useFormik } from "formik";
import React from "react";

const AuthForm = ({
  text,
  title,
  isLoading,
  children,
  handleSubmit,
  description,
  icon: Icon,
  btnClassName,
  belowButtonText,
  initialValues,
  validationSchema,
  onSubmit,
}) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  //   const [isLoading] = useState(false);
  // const handleSubmit = () => {};
  return (
    <Layout>
      <form
        onSubmit={handleSubmit || formik.handleSubmit}
        className="text-left bg-[#ffffff] py-7 px-5 rounded-[4px] space-y-3 w-[389px]"
      >
        {Icon && (
          <div className="text-[#ffffff]  flex justify-around text-center self-center mb-8 mt-4">
            <Icon className="w-20 h-20" />
          </div>
        )}
        <p className="typography-semibold-28px -mb-2">{title}</p>
        {description && (
          <p className="typography-normal-14px text-neutral-04">
            {description}
          </p>
        )}
        {children && typeof children === "function"
          ? children(formik)
          : children}
        {text && (
          <Button
            letterCase="capitalize"
            loading={isLoading}
            className={classNames("w-full !mt-[40px] 5", btnClassName)}
            type="submit"
          >
            {text}
          </Button>
        )}
        <>
          {belowButtonText && (
            <>
              {/* <div className="h-6" /> */}
              <p className="text-neutral-04 text-h1 text-center ">
                {belowButtonText}
              </p>
            </>
          )}
        </>
      </form>
    </Layout>
  );
};

export default AuthForm;
