// import Button from "@app/components/common/Button";
// import Search from "@app/components/common/Search";
// import { Plus } from "@app/components/Icon/icons";
// import { SectionHeader } from "@app/components/section";
// import { storage } from "@app/utils";
// import axios from "axios";
import { listAll, ref } from "firebase/storage";
import { storage } from "@app/config/firebase.config";
import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import GenresTable from "@app/views/Genres/components/GenresTable";
// import TvProgrammesTable from "../components/TvProgrammesTable";
import CSVInput from "./CSVInput";

import UploadController from "@app/components/UploadController/UploadController";

const TvProgrammesSection = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadObject, setUploadObject] = useState(null);
  //uploadeState: running | paused | stopped
  const [uploadState, setUploadState] = useState("running");

  // console.log(uploadState);
  return (
    <>
      {uploading ? (
        // uploading
        <div className="h-[200px] w-full">
          <div className="w-full h-full flex flex-col gap-2 items-center justify-center">
            {/* <Spinner color="dark" /> */}
            <UploadController
              file={file}
              progress={progress}
              state={uploadState}
              uploadObject={uploadObject}
              uploadTitle={<p> {`uploading CSV file: ${file?.name}`}</p>}
            />
          </div>
        </div>
      ) : (
        <CSVInput
          setFile={setFile}
          setProgress={setProgress}
          setUploadState={setUploadState}
          setUploadObject={setUploadObject}
          setUploading={setUploading}
        />
      )}
    </>
  );
};

export default TvProgrammesSection;
