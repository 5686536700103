import * as React from "react";
const SvgLink = (props) => (
  <svg
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.625 22.688a8.438 8.438 0 0 0 12.724.91l5.062-5.062a8.438 8.438 0 0 0-11.93-11.93L20.578 9.49"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.375 19.313a8.438 8.438 0 0 0-12.724-.912L6.59 23.464a8.438 8.438 0 0 0 11.93 11.93l2.886-2.885"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgLink;
