// import Button from "@app/components/common/Button";
import Form from "@app/components/common/Form";
import Input from "@app/components/common/Input";
import Select from "@app/components/Select";
import ThumbnailInput from "@app/components/ThumbnailInput";
// import Toggle from "@app/components/common/Toggle";
import Toast from "@app/components/Toast";
import { storage } from "@app/config/firebase.config";
import { useErrorHandler } from "@app/utils/hooks";
// import { useAddGenre } from "@app/utils/hooks/reactQuery/genre";
import { useAddLandingItem } from "@app/utils/hooks/reactQuery/landingItems";
// import { useInfiniteMovies } from "@app/utils/hooks/reactQuery/movie";
import classNames from "classnames";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
// import { findAllInRenderedTree } from "react-dom/test-utils";
import { toast } from "react-toastify";
// import * as Yup from "yup";

// const validationSchema = Yup.object({
//   genreTitle: Yup.string().trim().required("Genre name field can't be empty!"),
//   // genreSlug: Yup.string().trim().required("Genre slug field can't be empty!"),
// });
const initialValues = {
  // genreSlug: "",
  // type: "",
  // genreTitle: "",
  title: "",
  description: "",
  movieId: "",
  imageUrl: "",
};

const EPISODE_INFORMATION_FIELDS = [
  {
    info_type: "Title",
    placeholder: "",
    name: "title",
  },
  {
    info_type: "Description",
    placeholder: "",
    name: "description",
  },

  // {
  //   info_type: "Date Released",
  //   placeholder: new Date().getFullYear(),
  //   name: "dateReleased",
  // },
  // {
  //   info_type: "Genre",
  //   placeholder: "comedy",
  //   name: "genre",
  // },
  {
    info_type: "Movie Id",
    placeholder: "",
    name: "movieId",
  },
  {
    info_type: "Image Url",
    placeholder: "",
    name: "imageUrl",
  },
];

const TYPES = [
  {
    name: "Movie",
    value: "movie",
  },
  {
    name: "Advertisement",
    value: "advertisement",
  },
];

const AddGenreSection = () => {
  const { logout } = useState();
  const { isLoading, mutate, data } = useErrorHandler(useAddLandingItem);
  const [selectedItem, setSelectedItem] = useState(TYPES[0]);
  const [files, setFiles] = useState([]);
  const [showError, setShowError] = useState(false);
  // const {
  //   data: MOVIES,
  //   fetchNextPage,
  //   hasNextPage,
  //   isFetching,
  //   isFetchingNextPage,
  //   isLoading: isGettingMovies,
  // } = useErrorHandler(useInfiniteMovies, logout, "", limit.value);
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      let payload = {
        ...values,
        movieId: !!values.movieId.length ? values.movieId : "advert",
        type: selectedItem.value,
      };

      // console.log({ payload });
      if (!files[0] && !values.imageUrl.length) {
        setShowError(true);
      }

      if (values.imageUrl.length) {
        mutate(payload);
      } else if (files[0]) {
        setShowError(false);
        // setIsUploadingPoster(true);
        const uploadTask = uploadBytesResumable(
          ref(storage, `movie_thumbnails/${files[0]?.name}_landing_page_item`),
          files[0]
        );

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log("Upload is " + progress + "% done");
            // console.log({ snapshot });
            // setUploadState(snapshot.state);
            // setProgress(progress);
          },
          (error) => {
            mutate(payload);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              // setIsUploadingPoster(false);
              payload = { ...payload, imageUrl: downloadURL };
              // console.log({ payload });
              mutate(payload);
            });
          }
        );

        // mutate(payloa
      }
    },
  });
  useEffect(() => {
    // alert(isLoading);
    if (!isLoading && data) {
      toast.success(
        <Toast message={"Item added successfully"} toastType="success" />
      );
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  useEffect(() => {
    if (!!formik.values.imageUrl.length) setShowError(false);
  }, [formik.values.imageUrl.length]);
  return (
    <Form
      handleSubmit={!showError && formik.handleSubmit}
      buttonText="Send"
      handleToggle={formik.handleChange}
      formClassName="mb-[300px]"
      // toggleValue={formik.values.isPremium}
      // toggleLabel="Premium"
      // toggleName="isPremium"
      isLoading={isLoading}
    >
      <Select
        handleSelectedItem={setSelectedItem}
        items={TYPES}
        selectedItem={selectedItem}
        className="w-[60%] max-w-[300px]"
        labeflText={"Type"}
      />

      {EPISODE_INFORMATION_FIELDS.map((item, index) => {
        return (
          <Input
            labelTitle={item.info_type}
            name={item.name}
            value={
              item.name === "movieId" && selectedItem.value === "advertisement"
                ? ""
                : formik.values[item.name]
            }
            placeholder={item.placeholder || "placeholder"}
            showIcon={item.name === "password"}
            variant="transparent"
            key={index}
            message={
              formik.touched[item.name] &&
              formik.errors[item.name] && {
                type: "error",
                value: formik.errors[item.name],
              }
            }
            disabled={
              item.name === "movieId" && selectedItem.value === "advertisement"
            }
            textarea={item.name === "description"}
            onChange={formik.handleChange}
            className={classNames({
              "w-[60%] max-w-[300px]":
                item.name === "viewRating" ||
                item.name === "yearOfRelease" ||
                item.name === "movieLength" ||
                item.name === "season" ||
                item.name === "episodeNumber",
            })}
          />
        );
      })}

      <ThumbnailInput
        files={files}
        setFiles={setFiles}
        showError={showError}
        customError="You have to provide an image url in Url field or upload an image"
        // postThumbnail={
        //   <UploadController
        //     progress={progress}
        //     showButtons={false}
        //     size="small"
        //   />
        // }
      />
    </Form>
  );
};

export default AddGenreSection;
