import Button from "@app/components/common/Button";
import Input from "@app/components/common/Input";
// import { useDropzone } from "react-dropzone";

import Toggle from "@app/components/common/Toggle";
import React, { useState } from "react";
// import { Upload } from "@app/components/Icon/icons";
// import SvgXWhite from "@app/components/Icon/icons/XWhite";
// import { Thumbnail } from "@app/components/ThumbnailInput/components";
import ThumbnailInput from "@app/components/ThumbnailInput";

const AddTvProgrammesSection = () => {
  const [files, setFiles] = useState([]);

  return (
    <>
      <div className="flex flex-col gap-5">
        <Input labelTitle="Genre name" placeholder="Comedy" name="" value="" />
        {/* <Input labelTitle="Slug" placeholder="/slug-value" name="" value="" /> */}
        <ThumbnailInput files={files} setFiles={setFiles} />
        <Toggle
          handleChange={() => {}}
          name="Status"
          value=""
          labelTitle="Status"
        />
        <Button className="w-[80%] mx-auto min-w-[280px]">Send</Button>
      </div>
    </>
  );
};

export default AddTvProgrammesSection;
