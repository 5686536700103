import * as React from "react";
const SvgViews = (props) => (
  <svg
    viewBox="0 0 30 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.5.875v20.25h28.688V.875H.5ZM25.813 17.75H3.875v-5.063h21.938v5.063ZM3.875 9.312V4.25h21.938v5.063H3.875Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgViews;
