import React, { useEffect, useMemo, useState } from "react";
import Table from "@app/components/common/Table";
import classNames from "classnames";
import { Plus } from "@app/components/Icon/icons";
import { useNavigate } from "react-router-dom";
import { Delete, Pencil } from "@app/components/Icon/icons";
import ActionsButtonList from "@app/components/common/ActionsButtonList";
import { useGlobalStateContext } from "@app/utils/contexts.js/GlobalStateProvider";
import useCopyToClipboard from "@app/utils/hooks/useCopyToClipboard";
import { toast } from "react-toastify";
import Toast from "@app/components/Toast";
import { useErrorHandler } from "@app/utils/hooks";
import { useEditMovie } from "@app/utils/hooks/reactQuery/movie";
// import { Toast } from "react-toastify/dist/components";

const MOVIE_TYPE = {
  series: "Series",
  fullMovie: "Full Movie",
};

const MoviesTable = ({
  tableHeader,
  tableBody,
  isLoading,
  infiniteScroll,
  limit,
}) => {
  // console.log({ tableBody })
  const { mutate: editMovie, isLoading: isDeletingMovie } = useErrorHandler(
    useEditMovie,
    "",
    true
  );
  const [copiedText, copy] = useCopyToClipboard();
 
  const [movies, setMovies] = useState([]);
  const navigate = useNavigate();
  const { setState, state } = useGlobalStateContext();
  const ACTION_ITEMS = useMemo(
    () => [
      // { icon: Airplane, action: "send", onClick: (index) => {} },
      {
        disabled: (type) => (type === "series" ? false : true),
        icon: Plus,
        action: "add",
        onClick: (seriesId) => {
          navigate(`/movies/add-episode/${seriesId}`);
        },
      },
      {
        icon: Delete,
        action: "delete",
        onClick: (id) => {
          editMovie({ id, status: "archived" });
        },
      },
      {
        icon: Pencil,
        action: "edit",
        onClick: (id) => {
          // alert(id);
          // console.log({
          //   id,
          //   tableBody,
          //   movies,
          //   movie: movies?.find((movie) => movie.id === id),
          // });
          setState({
            ...state,
            movieToEdit: movies?.find((movie) => movie.id === id),
          });
          navigate(`/movies/edit/${id}`);
        },
      },
    ],
    [navigate, setState, state, movies]
  );

  // console.log({ tableBody, movies });
  useEffect(() => {
    if (tableBody && !!tableBody.length && Array.isArray(tableBody[0]?.data)) {
      tableBody?.forEach((item) =>
        setMovies((movies) => [...movies, ...item?.data])
      );
    }

    // if(!tableBody){
    //   navigate("/auth/sigin")
    // }

    return () => {
      // second/;
    };
  }, [navigate, tableBody]);

  const handleCopyToClipboard = (e, whatToCopy, whatToPutInMessage) => {
    e.stopPropagation();
    copy(whatToCopy);
    toast.success(
      <Toast
        toastType={"success"}
        message={`${whatToPutInMessage} copied succesfully!`}
      />
    );
  };

  return (
    <Table
      isLoading={isLoading}
      emptyTableText={
        !!tableBody?.length && !isLoading ? null : "There are no movies yet!"
      }
    >
      <Table.Header data={tableHeader} />
      <Table.Body>
        {tableBody
          ?.flatMap((group, i) =>
            group?.data?.flatMap((item, index) => {
              return (
                <tr
                  className={classNames("h-16  ", {
                    "bg-black-01": !((i + index) % 2),
                    "cursor-pointer hover:scale-[101%] duration-200 ease-out":
                      item.type === "series",
                  })}
                  key={`movies_${item.id}`}
                  onClick={() => {
                    if (item.type === "series") {
                      setState(item);
                      navigate(`/movies/${item.id}/episodes`);
                    }
                  }}
                >
                  <td className="text-center">{index + limit * i + 1}</td>
                  <td className="h-28 flex justify-start ">
                    <div className="h-full aspect-[150/74] relative overflow-hidden align-middle">
                      <img
                        src={item?.poster}
                        alt={item?.title}
                        className="absolute top-0 left-0 right-0 bottom-0 cursor-pointer"
                        onClick={(e) =>
                          handleCopyToClipboard(
                            e,
                            item?.poster,
                            "Movie image url"
                          )
                        }
                      />
                    </div>
                  </td>
                  <td className="break-word">
                    <p
                      className="cursor-pointer"
                      onClick={(e) =>
                        handleCopyToClipboard(e, item?.title, "Movie title")
                      }
                    >
                      {item?.title}
                    </p>
                    <p
                      className="text-h1 hover:underline cursor-pointer"
                      onClick={(e) =>
                        handleCopyToClipboard(e, item?.id, "Movie id")
                      }
                    >
                      [{item?.id}]
                    </p>
                  </td>
                  <td className=" overflow-hidden relative ">
                    {/* <Tooltip message={item?.description}> */}
                    <p
                      className="multiline-ellipsis h-full "
                      onClick={(e) =>
                        handleCopyToClipboard(
                          e,
                          item?.description,
                          "Movie description"
                        )
                      }
                    >
                      {item?.description}
                    </p>
                    {/* </Tooltip> */}
                  </td>
                  <td>{MOVIE_TYPE[item.type]}</td>
                  <td>
                    <ActionsButtonList
                      items={ACTION_ITEMS}
                      id={item.id}
                      type={item.type}
                      loading={isDeletingMovie}
                    />
                  </td>
                </tr>
              );
            })
          )
          .flat(2)}
      </Table.Body>
    </Table>
  );
};

export default MoviesTable;
