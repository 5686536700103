import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import { useErrorHandler } from "@app/utils/hooks";
import { useApproveAdmin } from "@app/utils/hooks/reactQuery/admin";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import { Toast } from "react-/toastify/dist/components";
import Toast from "@app/components/Toast";
// import Tooltip from "@app/components/Tooltip";

const ActionsButtonList = ({ items, id, type, loading }) => {
  const [action, setAction] = useState(null);
  const { logout } = useAuthContext();
  const { data, isLoading, approveDeleteSuspend } = useErrorHandler(
    useApproveAdmin,
    logout,
    id,
    "",
    "",
    action
  );
  useEffect(() => {
    if (data) {
      toast.success(<Toast message={data?.message} toastType="success" />);
    }
  }, [data]);

  return (
    <div className="space-x-3 flex items-center">
      {items.map((item, index) => (
        <button
          key={index}
          className={classNames(
            "w-8 h-8 p-2 rounded-full text-[#ffffff] cursor-pointer hover:scale-125 duration-500 ease-out disabled:opacity-60 disabled:cursor-not-allowed disabled:hover:scale-100",
            {
              "bg-secondary-06":
                item.action === "edit" || item.action === "suspend",
              "bg-primary-06": item.action === "delete",
              "bg-success": item.action === "send",
              "bg-[#1CCB57]":
                item.action === "approve" || item.action === "add",
              "animate-pulse disabled:animate-none": isLoading,
            }
          )}
          disabled={(item?.disabled && item?.disabled(type || id)) || isLoading || loading}
          onClick={(e) => {
            e.stopPropagation();
            setAction(item.action);
            return item.onClick
              ? item.onClick(id)
              : approveDeleteSuspend(item.action);
          }}
        >
          <item.icon />
        </button>
      ))}
    </div>
  );
};

export default ActionsButtonList;
