import * as React from "react";
const SvgTickPerson = (props) => (
  <svg
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 8c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4Zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2ZM2 14c.2-.63 2.57-1.68 4.96-1.94l2.04-2A9.34 9.34 0 0 0 8 10c-2.67 0-8 1.34-8 4v2h9l-2-2H2Zm15.6-5.5-5.13 5.17-2.07-2.08L9 13l3.47 3.5L19 9.91 17.6 8.5Z"
      fill="currentColor"
      fillOpacity={0.87}
    />
  </svg>
);
export default SvgTickPerson;
