// import { whenProd } from "@craco/craco";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import React from "react";
import {  useLocation } from "react-router-dom";

const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

const Header = ({
  beforeSlashText,
  afterSlashText,
  beforeSlashLink,
  afterSlashLink,
}) => {
  const {
    user: { email, type },
  } = useAuthContext();
  const { pathname } = useLocation();
  const path =
    pathname === "/"
      ? "Dashboard"
      : capitalize(pathname.slice(1).split("/")[0]);
  return (
    <header className=" min-h-24  bg-[#ffffff] py-4 mb-1 flex  px-4">
      <div className="text-left mb-8">
        <h1
          className="text-h3 leading-l5 text-neutral-05 text-left font-semibold"
          aria-level={1}
        >
          {path.search("-") > -1 ? path.split("-").join(" ") : path}
        </h1>
        {/* <div className="">
          <Link to={beforeSlashLink}>
            <span className="hover:underline text-primary-06 underline-offset-2">
              {beforeSlashText}
            </span>
          </Link>{" "}
          /{" "}
          <Link to={afterSlashLink}>
            <span className="text-neutral-02 hover:underline underline-offset-2">
              {afterSlashText}
            </span>
          </Link>
        </div> */}
      </div>
      <div className="flex-1 flex gap-4 items-stretch self-end justify-end mt-[20px] text-right">
        <p className="flex space-between gap-6  py-2 px-4  bg-[#FEF5EA] rounded-lg items-end text-h1  font-normal">
          <span className="font-normal">
            {type === "admin" ? "Admin" : "Super Admin"}
          </span>
          <span>{email}</span>
        </p>
        {/* <span className="aspect-square h-8 self-stretch bg-primary-02  rounded-[4px]"></span> */}
      </div>
    </header>
  );
};

export default Header;
