const REGEX_EMAIL =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const REGEX_POSITIVE_WHOLE_NUMBER = /^[1-9]\d*$/;

const STORAGE_PREFIX = "waptv_admin_panel_";
const SUPER_ADMIN = "super-admin";
const MANAGE_ADMINS = "Manage Admins";

// possible messages sent as error response
const INVALID_AUTH_MESSAGE = "Invalid auth token";
const AUTH_IS_REQUIRED = "Auth is required";
const AWAITING_SIGNUP = "awaiting-signup";
const PENDING_APPROVAL = "pending-approval";
const SUSPENDED = "suspended";
const DELETED = "deleted";
const APPROVED = "approved";
const SUCCESS_STATUSCODE_200 = 200;

export {
  REGEX_EMAIL,
  STORAGE_PREFIX,
  SUPER_ADMIN,
  MANAGE_ADMINS,
  INVALID_AUTH_MESSAGE,
  AUTH_IS_REQUIRED,
  AWAITING_SIGNUP,
  PENDING_APPROVAL,
  APPROVED,
  REGEX_POSITIVE_WHOLE_NUMBER,
  SUSPENDED,
  DELETED,
  SUCCESS_STATUSCODE_200,
};
