// import { Input } from 'postcss'
import Button from "@app/components/common/Button";
import Input from "@app/components/common/Input";
import Toggle from "@app/components/common/Toggle";
import Toast from "@app/components/Toast";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import { useErrorHandler } from "@app/utils/hooks";
import { useEditGenre } from "@app/utils/hooks/reactQuery/genre";
// import Logout from "@app/views/Logout";
// import { isEditableInputOrTextArea } from "@testing-library/user-event/dist/types/utils";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
// import { findAllInRenderedTree } from "react-dom/test-utils";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const EditGenreSection = () => {
  const { logout } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );

  const { isLoading, mutate, data } = useErrorHandler(
    useEditGenre,
    logout,
    params.get("slug")
  );
  const [formData, setFormData] = useState({
    active: Boolean(parseInt(params.get("active"))),
    genreTitle: params.get("genre"),
    genreSlug: params.get("slug"),
  });

  useEffect(() => {
    if (!(params.get("active") && params.get("genre") && params.get("slug"))) {
      navigate("-1");
    }
  }, [navigate, params]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.genreTitle !== "") {
      mutate(formData);
      await data;
    }
  };

  useEffect(() => {
    if (data && !isLoading) {
      toast.success(<Toast message={"Genre was successfully updated."} toastType="success" />);
      navigate("/manage-genres ", { replace: true });
    }
  }, [data, isLoading, navigate]);
  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-5">
      <Input
        labelTitle="Previous Genre"
        placeholder="Comedy"
        name="genre"
        value={formData.genreTitle}
        onChange={(e) =>
          setFormData({ ...formData, genreTitle: e.target.value })
        }
        loading={isLoading}
      />
      <Input
        labelTitle="Read-Only Slug"
        placeholder="/slug-value"
        name="slug"
        value={formData.genreSlug}
        disabled
      />
      <Toggle
        handleChange={(e) => {
          setFormData({ ...formData, active: !formData.active });
        }}
        name="Status"
        value={formData.active}
        labelTitle="Status"
        loading={isLoading}
      />
      <Button
        className="w-[80%] mx-auto min-w-[280px]"
        loading={isLoading}
        type="submit"
      >
        Update
      </Button>
    </form>
  );
};

export default EditGenreSection;
