import { Airplane } from "@app/components/Icon/icons";
import classNames from "classnames";
import React from "react";
import Button from "../Button";
import Toggle from "../Toggle";

const Form = ({
  handleSubmit,
  children,
  handleToggle,
  formClassName,
  toggleValue,
  toggleLabel,
  toggleName,
  buttonText = "Send",
  showIcon = true,
  isLoading,
}) => {
  return (
    <form
      onSubmit={handleSubmit}
      className={classNames("flex flex-col gap-4 my-4", formClassName)}
    >
      {children}

      {toggleName && (
        <Toggle
          labelTitle={toggleLabel}
          name={toggleName}
          value={toggleValue}
          handleChange={handleToggle}
          // toggleSize="small"
        />
      )}
      <Button
        iconPosition="right"
        icon={showIcon && Airplane}
        className="self-center lg:w-[500px] w-[700px] max-w-[800px] mt-5"
        type="submit"
        loading={isLoading}
      >
        {buttonText}
      </Button>
    </form>
  );
};

export default Form;
