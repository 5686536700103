import Button from "@app/components/common/Button";
import { ArrowRight, Svg404 } from "@app/components/Icon/icons";
import { Link } from "react-router-dom";
import React from "react";

const NotFound = () => {
  return (
    <section className=" h-screen w-full flex justify-center items-center gap-10">
      <div className="">
        <h2 className="font-extrabold text-h6 mb-7">
          Oooops...
          <p className="font-normal text-h4">Page Not Found</p>
        </h2>
        <Link to="/">
          <Button className="" iconPosition="right" icon={ArrowRight}>
            Go to Home
          </Button>
        </Link>
      </div>
      <div>
        <Svg404 />
      </div>
    </section>
  );
};

export default NotFound;
