import {
  addSubscription,
  deleteSubscription,
  getAllSubscriptions,
} from "@app/api/subscriptionapi";

const { useQuery, useQueryClient, useMutation } = require("react-query");

// const "genres" = ["genres"];

const useSubscriptions = () => {
  const { isFetching, data, isError, error, isLoading } = useQuery({
    queryKey: "subscriptions",
    queryFn: getAllSubscriptions,
    retry: false,
  });
  return { isFetching, data, isError, error, isLoading };
};

const useAddSubscription = () => {
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, isLoading, error } = useMutation(
    addSubscription,
    {
      onMutate: async (newSubscription) => {
        const previousSubscriptions = await queryClient.getQueryData(
          "subscriptions"
        );
        queryClient.setQueryData("subscriptions", (old) => {
          old?.push(newSubscription);
          return old;
        });

        return { previousSubscriptions };
      },

      onError: (err, subscriptionToDelete, context) => {
        queryClient.setQueryData(
          "subscriptions",
          context.previousSubscriptions
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: "subscriptions" });
      },
    }
  );
  return { mutate, isLoading, data, isError, isSuccess, error };
};

const useEditSubscription = () => {
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, isLoading, error } = useMutation(
    deleteSubscription,
    {
      onMutate: async (subscriptionToDelete) => {
        //   // Cancel any outgoing refetches
        //   // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({ queryKey: "subscriptions" });

        //   // Snapshot the previous value
        const previousSubscriptions = await queryClient.getQueryData(
          "subscriptions"
        );
        //   // Optimistically update to the new value
        queryClient.setQueryData("subscriptions", (old) => {
          return old?.filter((item) => item.id !== subscriptionToDelete);
        });

        //   // Return a context object with the snapshotted value
        return { previousSubscriptions };
      },
      // // If the mutation fails,
      // // use the context returned from onMutate to roll back
      onError: (err, subscriptionToDelete, context) => {
        queryClient.setQueryData(
          "subscriptions",
          context.previousSubscriptions
        );
      },
      // // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: "subscriptions" });
      },
    }
  );

  return {
    mutate,
    data,
    isError,
    isSuccess,
    isLoading,
    error,
  };
};

export { useSubscriptions, useAddSubscription, useEditSubscription };
