const { default: api } = require("./api");

const addNotification = (payload) => api.post("/send-notification", payload);
const getAllNotifications = ({ lastNotificationId, limit }) =>
  api.get(
    `/get-notifications?limit=${limit}${
      !!lastNotificationId?.length
        ? `&lastNotificationId=${lastNotificationId}`
        : ""
    }`
  );

export { addNotification, getAllNotifications };
