import { addAdmin, approveAdmin, getAdmins } from "@app/api/adminapi";
import storage from "@app/utils/storage";

const { useQuery, useMutation, useQueryClient } = require("react-query");

const ADMINS = "admins";

const useAdmins = () => {
  const { isFetching, data, isError, error, isLoading } = useQuery({
    queryKey: ADMINS,
    queryFn: getAdmins,
    retry: false,
  });
  return { isFetching, data, isError, error, isLoading };
};

const useAddAdmin = () => {
  // const id = nanoid();
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, isLoading, isFetching, error } =
    useMutation({
      mutationFn: addAdmin,

      onMutate: async (newGenre) => {
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({ queryKey: ADMINS });

        // Snapshot the previous value
        const previousGenres = queryClient.getQueryData(ADMINS);
        // Optimistically update to the new value
        queryClient.setQueryData(ADMINS, (old) => [
          ...old,
          { ...newGenre, isVerified: false, status: "awaiting-signup" },
        ]);

        // Return a context object with the snapshotted value
        return { previousGenres };
      },
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      onError: (err, newGenre, context) => {
        queryClient.setQueryData(ADMINS, context.previousGenres);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ADMINS });
      },
    });
  return { mutate, isLoading, data, isError, isSuccess, isFetching, error };
};

const useApproveAdmin = (id) => {
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, isLoading, isFetching, error } =
    useMutation({
      mutationFn: (action) => approveAdmin(id, action),
      // mutationKey: [ADMINS, id],
      onMutate: async (newGenre) => {
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({ queryKey: ADMINS });

        // Snapshot the previous value
        const previousGenres = queryClient.getQueryData(ADMINS);
        // Optimistically update to the new value
        queryClient.setQueryData(ADMINS, (old) => [
          ...old,
          { ...newGenre, isVerified: false, status: "awaiting-signup" },
        ]);

        // Return a context object with the snapshotted value
        return { previousGenres };
      },
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      onError: (err, newGenre, context) => {
        queryClient.setQueryData(ADMINS, context.previousGenres);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ADMINS });
      },
      // onSuccess: () => {
      //   // Invalidate and refetch
      //   queryClient.invalidateQueries({ queryKey: ['genres'] })
      // },
    });
  return {
    approveDeleteSuspend: mutate,
    isLoading,
    data,
    isError,
    isSuccess,
    isFetching,
    error,
  };
};

export { useAdmins, useAddAdmin, useApproveAdmin };
