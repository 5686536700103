import classNames from "classnames";
import { useMultipleSelection, useSelect } from "downshift";
import React, { useEffect } from "react";

function getBooksFilter(selectedItems) {
  return function booksFilter(book) {
    return selectedItems.indexOf(book) < 0;
  };
}

const MultipleSelectBox = ({
  labelText,
  list,
  handleSelectedItems,
  defaultSelectedItems,
}) => {
  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection({
    defaultSelectedItems: defaultSelectedItems || undefined,
  });
  const items = list?.filter(getBooksFilter(selectedItems));
  useEffect(
    () => handleSelectedItems([...new Set(selectedItems)]),
    [handleSelectedItems, selectedItems]
  );
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    selectedItem: null,
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    items,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      // eslint-disable-next-line default-case
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
          };
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ToggleKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (selectedItem) {
            addSelectedItem(selectedItem);
          }
          break;
        default:
          break;
      }
    },
  });

  return (
    <div className="relative ">
      <div className="flex flex-col ">
        <label className="text-h2 leading-l7 mb-[2px]" {...getLabelProps()}>
          {labelText}
        </label>
        <div className="shadow-sm bg-[#fff] inline-flex gap-2 items-center flex-wrap py-2 px-4 border-[1px] border-neutral-02 focus:border-secondary-06 focus-within:border-secondary-06 rounded-[4px]">
          {[...new Set(selectedItems)].map(function renderSelectedItem(
            selectedItemForRender,
            index
          ) {
            return (
              <span
                className="bg-neutral-01 rounded-[4px] px-2 text-h2 text-black-04 py-1 focus:bg-secondary-04"
                key={`selected-item-${index}`}
                {...getSelectedItemProps({
                  selectedItem: selectedItemForRender,
                  index,
                })}
              >
                {selectedItemForRender?.genreTitle}
                <span
                  className="px-1 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeSelectedItem(selectedItemForRender);
                  }}
                >
                  &#10005;
                </span>
              </span>
            );
          })}
          <div
            className="px-2 py-1 outline-[1px] text-h2 text-[#fff] outline-black-03  cursor-pointer bg-black-03 rounded-[4px] focus:border-[1px] focus-within:border-[1px]"
            {...getToggleButtonProps(
              getDropdownProps({ preventKeyAction: isOpen })
            )}
          >
            Add Genre
            {/* <Plus className="w-6 h-6" strokeWidth={1} /> */}
          </div>
        </div>
      </div>
      <ul
        {...getMenuProps()}
        className="absolute p-0 bg-[#FFFFFF] z-30 shadow-md max-h-80 overflow-y-auto overflow-x-hidden w-full"
      >
        {isOpen &&
          items.map((item, index) => (
            <li
              className={classNames(
                highlightedIndex === index && "bg-secondary-03",
                selectedItem === item && "font-bold",
                "py-2 px-3 shadow-sm flex flex-col cursor-pointer"
              )}
              key={`${item?.genreSlug}${index}`}
              {...getItemProps({ item, index })}
            >
              {/* <span>{item?.genreSlug}</span> */}
              <span className="text-h2 text-black-03">{item?.genreTitle}</span>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default MultipleSelectBox;
