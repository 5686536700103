import InnerDashboardLayout from '@app/layouts/InnerDashboardLayout'
import React from 'react'
import ArchivedMoviesSection from './ArchivedMoviesSection'

const ArchivedMovie = () => {
  return (
    <InnerDashboardLayout  children={<ArchivedMoviesSection/>}/>
  )
}

export default ArchivedMovie