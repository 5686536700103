import classNames from "classnames";
import React from "react";

const Skeleton = ({ width = 20, height = 12, fullWidth }) => {
  return (
    <span
      style={{ width: `${width}`, height: `${height}` }}
      className={classNames("inline-block animate-pulse bg-neutral-01 ", {
        "w-full": fullWidth,
      })}
    ></span>
  );
};

export default Skeleton;
