import InnerDashboardLayout from '@app/layouts/InnerDashboardLayout'
import React from 'react'
import SubscriptionsSection from './SubscriptionsSection'

const Subscriptions = () => {
  return (
    <InnerDashboardLayout children={<SubscriptionsSection/>}/>
  )
}

export default Subscriptions