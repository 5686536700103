import { SectionHeader } from "@app/components/section";
import React from "react";
import AddEpisodeForm from "./AddEpisodeForm";

const AppEpisodeSection = () => {
  return (
    <div>
      <SectionHeader title="Add Episode" />
      <AddEpisodeForm />
    </div>
  );
};

export default AppEpisodeSection;
