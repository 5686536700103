// import { Form } from 'formik'
import Form from "@app/components/common/Form";
import Input from "@app/components/common/Input";
import Select from "@app/components/Select";
import Toast from "@app/components/Toast";
// import { REGEX_POSITIVE_WHOLE_NUMBER } from "@app/constants";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import { useErrorHandler } from "@app/utils/hooks";
import { useAddSubscription } from "@app/utils/hooks/reactQuery/subscription";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";

const initialValues = {
  title: "",
  description: "",
  price: "",
  duration: "",
};

const INFO = [
  {
    type: "Title",
    name: "title",

    placeholder: "",
  },
  {
    type: "Description",
    name: "description",
    placeholder: "",
  },
  {
    type: "Price(in Naira)",
    name: "price",
    placeholder: "",
  },
];

const DURATIONS = [
  {
    name: "Weekly",
    value: "weekly",
  },
  {
    name: "Monthly",
    value: "monthly",
  },
  {
    name: "Quarterly",
    value: "quarterly",
  },
  {
    name: "Annually",
    value: "annually",
  },
];

const validationSchema = Yup.object({
  title: Yup.string().required("Title field cannot be empty!"),
  description: Yup.string().required("Description field cannot be empty!"),

  price: Yup.number()
    .min(100, "Price cannot be less than N100")
    .required("Price field cannot be empty!"),
});

const AddSubscriptionSection = () => {
  const [duration, setDuration] = useState(DURATIONS[0]);
  const { logout } = useAuthContext();
  const { mutate, data, isLoading, error } = useErrorHandler(
    useAddSubscription,
    logout
  );
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      mutate({
        ...values,
        duration: duration.value,
        price: parseInt(values.price),
      });
    },
  });

  useEffect(() => {
    if (data?.statusCode === 200 && !isLoading && !error) {
      toast.success(
        <Toast
          message="Subscription was successfully added!"
          toastType="success"
        />
      );
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data?.statusCode, error, isLoading]);
  return (
    <Form
      handleSubmit={formik.handleSubmit}
      isLoading={isLoading}
      buttonText="Add Subscription"
      formClassName="mb-32"
      showIcon={false}
      // handleToggle={handleToggle}
    >
      {INFO.map((item) => (
        <Input
          labelTitle={item.type}
          placeholder={item.placeholder}
          onBlur={formik.handleBlur}
          loading={isLoading}
          variant="transparent"
          value={formik.values[item.name]}
          message={
            formik.touched[item.name] &&
            formik.errors[item.name] && {
              type: "error",
              value: formik.errors[item.name],
            }
          }
          textarea={item.name === "description"}
          name={item.name}
          onChange={formik.handleChange}
        />
      ))}
      <div className="w-[30%] max-w-[300px]">
        <Select
          labeflText="Duration"
          handleSelectedItem={setDuration}
          selectedItem={duration}
          items={DURATIONS}
        />
      </div>
    </Form>
  );
};

export default AddSubscriptionSection;
