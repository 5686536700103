import { SectionHeader } from "@app/components/section";
// import Select from "@app/components/Select";
import { useGlobalStateContext } from "@app/utils/contexts.js/GlobalStateProvider";
import React from "react";
import { Navigate } from "react-router-dom";
// import EditEpisodeForm from "./EditEpisodeForm";
import EditMovieForm from "./EditMovieForm";

// const EPISODES = [
//   {
//     name: "Episode 1",
//     value: "episode1",
//   },
//   {
//     name: "Episode 2",
//     value: "episode2",
//   },
// ];

const EditMovieSection = () => {
  const { state } = useGlobalStateContext();
  // console.log({ state });
  // const [editEpisode, setEditEpisode] = useState(true);

  // const EpisodesPassedtoSelect = EPISODES.map((item) => ({
  //   name: item.name,
  //   value: item.value,
  // }));
  // const [selectedEpisode, setSelctedEpisode] = useState(
  //   EpisodesPassedtoSelect[0
  // )
  return (
    <div>
      <div className="flex gap-5">
        {/* <div onClick={() => setEditEpisode(null)} className="cursor-pointer"> */}
          <SectionHeader title="Edit Movie"  />
        {/* </div> */}
        {/* <div onClick={() => setEditEpisode(true)} className="cursor-pointer">
          <SectionHeader title="Edit Episode" notSelected={!editEpisode} />
        </div> */}
      </div>
      {/* {movieToEdit ? (
        editEpisode ? (
          <EditEpisodeForm
            episodeToEdit={selectedEpisode}
            editEpisode={editEpisode}
          />
        ) : ( */}
      {state?.movieToEdit ? (
        <EditMovieForm movieToEdit={state?.movieToEdit} />
      ) : (
        <Navigate to="/movies" />
      )}
      {/* ) */}
      {/* ) : ( */}
      {/* // <Navigate to="/movies" /> */}
      {/* )} */}
    </div>
  );
};

export default EditMovieSection;
