// import Button from "@app/components/common/Button";
// import Input from "@app/components/common/Input";
import InnerDashboardLayout from "@app/layouts/InnerDashboardLayout";
import React from "react";
import MovieInformation from "./components/MovieInformation";

// const ImportMovieFromTMDB = () => {
//   const handleSubmit = (e) => e.preventDefault();
//   return (
//     <form
//       className="mb-1 md:w-full mx-auto flex flex-col items-stretch self-center gap-1"
//       onSubmit={handleSubmit}
//     >
//       <Button size="large"> Import movies/videos from TMDB </Button>
//       <Input message={{ type: "" }} placeholder="Enter TMDBS ID. EX: 231051" />
//     </form>
//   );
// };

const AddMovies = () => {
  return (
    <InnerDashboardLayout
      // preSection={ImportMovieFromTMDB}
      children={<MovieInformation />}
    />
  );
};

export default AddMovies;
