import api from "./api";
// movie/add-episodes

// const getAllGenres = (user) => api.get("/all-genres").then((res) => res.data);
const addEpisode = (payload) =>
  api.post("/movie/add-episodes", payload).then((res) => res);
const getAllMovies = ({ lastMovieId, limit }) =>
  api
    .get(
      `/movie/all?limit=${limit}${
        !!lastMovieId?.length ? `&lastMovieId=${lastMovieId}` : ""
      }`
    )
    .then((res) => res);
const addMovie = (payload) =>
  api.post("/movie/add", payload).then((res) => res);
const addEpisodeToMovie = (payload) =>
  api.post("/movie/add-episodes", payload).then((res) => res);

const editMovie = (payload) =>
  api.post("/movie/edit", payload).then((res) => res);

const editEpisode = (payload) => api.post("/movie/edit-episode", payload);
// const editGenre = (modifiedGenre) =>
//   api.post("/edit-genre", modifiedGenre).then((res) => res);
// const deleteGenre = (user) =>
//   api.delete("/delete-genre", user).then((res) => res.data);
const getAllEpisodes = (id) =>
  api.get(`/movie-details?movieId=${id}`).then((res) => res.data);
const getArchivedMovies = ()=> api.get(`/movie/archive`).then(res => res.data)
export {
  //  getAllGenres,
  addEpisode,
  getAllMovies,
  addMovie,
  addEpisodeToMovie,
  editMovie,
  getAllEpisodes,
  editEpisode,
  getArchivedMovies
  //    editGenre,
  // deleteGenre
};
