import * as React from "react";
const SvgCancelPerson = (props) => (
  <svg
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 5c1.1 0 2 .9 2 2a2 2 0 0 1-1.67 1.97l-2.31-2.31C13.19 5.72 14.01 5 15 5Zm0-2c-2.21 0-4 1.79-4 4 0 .18.03.35.05.52l3.43 3.43c.17.02.34.05.52.05 2.21 0 4-1.79 4-4s-1.79-4-4-4Zm1.69 10.16L22.53 19H23v-2c0-2.14-3.56-3.5-6.31-3.84Zm-3.68 1.97L14.88 17H9c.08-.24.88-1.01 2.91-1.57l1.1-.3ZM1.41.71 0 2.12l4 4V9H1v2h3v3h2v-3h2.88l2.51 2.51C9.19 14.11 7 15.3 7 17v2h9.88l4 4 1.41-1.41L1.41.71ZM6 9v-.88l.88.88H6Z"
      fill="currentColor"
      fillOpacity={0.87}
    />
  </svg>
);
export default SvgCancelPerson;
