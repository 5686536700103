import { storage } from "@app/utils";
import React, { useState } from "react";
import { useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import AuthForm from "../components/AuthForm";
import InputBoxItem from "./InputBoxItem";

const description = (email) => {
  return (
    <>
      An OTP has been sent to{" "}
      <span className="text-neutral-07 font-medium">
        {storage.get("email")}
      </span>
      , pleease enter the 6 digit code in the box below{" "}
    </>
  );
};

const enums = {
  FIRST: "first",
  SECOND: "second",
  THIRD: "third",
  FOURTH: "fourth",
  FIFTH: "fifth",
  SIXTH: "sixth",
};

// const FORBIDDEN_CHARACTERS = ["];

const Verify = () => {
  const email = storage.get("email");
  const id = storage.get("id");
  // const code = storage.get("otp");

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    [enums.FIRST]: "",
    [enums.SECOND]: "",
    [enums.THIRD]: "",
    [enums.FOURTH]: "",
    [enums.FIFTH]: "",
    [enums.SIXTH]: "",
  });

  const OTP_ENTERED_FULLY =
    formData[enums.FIRST] !== "" &&
    formData[enums.SECOND] !== "" &&
    formData[enums.THIRD] !== "" &&
    formData[enums.FOURTH] !== "" &&
    formData[enums.FIFTH] !== "" &&
    formData[enums.SIXTH] !== "";

  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const fourthRef = useRef(null);
  const fifthRef = useRef(null);
  const sixthRef = useRef(null);

  const BOXES_VALUES = [
    { name: enums.FIRST, ref: firstRef },
    { name: enums.SECOND, ref: secondRef },
    { name: enums.THIRD, ref: thirdRef },
    { name: enums.FOURTH, ref: fourthRef },
    { name: enums.FIFTH, ref: fifthRef },
    { name: enums.SIXTH, ref: sixthRef },
  ];
  const [isLoading,] = useState(false);
  const handleSubmit = async (e) => {
  
    e.preventDefault();
    setShow(!OTP_ENTERED_FULLY);
   
    const OTP_VALUE = Object.values(formData).join("");
    if (OTP_ENTERED_FULLY) {
      storage.set("otp", OTP_VALUE);
      navigate("/auth/reset");
    }
    // alert(OTP_VALUE);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // alert(value);
    if (value.length > 1) return;
    // if(value == '') set
    // alert(value);
    if (/^[0-9]$/.test(value)) {
      if (name === enums.FIRST) secondRef.current.focus();
      if (name === enums.SECOND) thirdRef.current.focus();
      if (name === enums.THIRD) fourthRef.current.focus();
      if (name === enums.FOURTH) fifthRef.current.focus();
      if (name === enums.FIFTH) sixthRef.current.focus();
      // return;
      setFormData({ ...formData, [name]: value });
    }
    if (value === "") {

      if (name === enums.SECOND) firstRef.current.focus();
      if (name === enums.THIRD) secondRef.current.focus();
      if (name === enums.FOURTH) thirdRef.current.focus();
      if (name === enums.FIFTH) fourthRef.current.focus();
      if (name === enums.SIXTH) fifthRef.current.focus();
      // return;
      setFormData({ ...formData, [name]: value });
    }
    // setFormData(value);
    // setFormData({ ...formData, [name]: value });
  };

  if (!id || !email) {
    return <Navigate to="/auth/forgot" replace />;
  }

  return (
    <AuthForm
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      text="Continue"
      title="Verify Email"
      description={description(storage.get("EMAIL"))}
      btnClassName="!mt-1"
      // belowButtonText={
      //   <Button
      //     variant="link"
      //     className=" text-center w-full p-0 "
      //     letterCase=""
      //     onClick={handleResendOTP}
      //     type="button"
      //     // className="p-0"
      //   >
      //     Resend new OTP
      //   </Button>
      // }
    >
      <div className="py-2"></div>
      <div className="flex items-center gap-3 justify-center ">
        {BOXES_VALUES.map((item, index) => (
          <InputBoxItem
            key={index}
            ref={item?.ref}
            name={item.name}
            value={formData[item.name]}
            handleChange={handleChange}
          />
        ))}
      </div>
      {show && (
        <p className="text-h1 text-primary-04 text-center">
          {" "}
          Seems you omitted at least a digit!
        </p>
      )}
    </AuthForm>
  );
};

export default Verify;
