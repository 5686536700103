import api from "./api";

const getAdmins = () => api.get("/my-admins").then((res) => res.data);

// return reponse as is so as to have access to the reponse's message property
const addAdmin = (admin) => api.post("/new", admin).then((res) => res);
// const verifyUser = (user) => api.post("account/email-verification", user ).then(res => res.data)

const approveAdmin = (adminId, action) =>
  api
    .post("/change-admin-account-status", {
      action,
      adminId,
    })
    .then((res) => res);

export { getAdmins, addAdmin, approveAdmin };
