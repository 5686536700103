import React from "react";
import AuthForm from "../components/AuthForm";

const NotApproved = () => {
  return (
    <AuthForm
      title="Account not yet approved"
      description="Your account is not yet approved, reach out to super admin for approval"
    />
  );
};

export default NotApproved;
