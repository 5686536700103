import * as React from "react";
const SvgUpload = (props) => (
  <svg
    viewBox="0 0 57 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#upload_svg__clip0_539_23332)" fill="#A3A1A1">
      <path d="M47.65 20.222v-.498A15.96 15.96 0 0 0 31.393 4.17a16.287 16.287 0 0 0-14.934 9.489A15.151 15.151 0 0 0 2.99 28.622 14.965 14.965 0 0 0 18 43.556h5.834v-3.112H18a11.9 11.9 0 0 1-.389-23.737h1.043l.358-.98a13.114 13.114 0 0 1 12.444-8.4A12.849 12.849 0 0 1 44.6 19.77c.022.415.022.83 0 1.244l-.124 1.12 1.011.467a9.333 9.333 0 0 1-3.951 17.842h-8.369v3.111h8.369a12.444 12.444 0 0 0 6.113-23.333Z" />
      <path d="M35.158 33.989a1.555 1.555 0 0 0 1.12-2.66l-7.777-7.778-7.778 7.778a1.556 1.556 0 0 0 2.193 2.193l4.029-3.966V48.61a1.556 1.556 0 1 0 3.111 0V29.556l3.998 3.997c.296.286.693.443 1.104.436Z" />
    </g>
  </svg>
);
export default SvgUpload;
