import * as React from "react";
const SvgMonitor = (props) => (
  <svg
    viewBox="0 0 38 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M34.188.813H3.811A3.385 3.385 0 0 0 .438 4.188v20.25a3.385 3.385 0 0 0 3.374 3.375h8.438v3.375h13.5v-3.375h8.438c1.856 0 3.358-1.52 3.358-3.375l.017-20.25A3.385 3.385 0 0 0 34.187.813Zm0 23.625H3.811V4.188h30.375v20.25Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMonitor;
