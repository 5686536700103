import { SectionHeader } from "@app/components/section";
import { useGlobalStateContext } from "@app/utils/contexts.js/GlobalStateProvider";
import React from "react";
import EditEpisodeForm from "./EditEpisodeForm";

const EditEpisodeSection = () => {
    const {state} = useGlobalStateContext()
  return (
    <div>
      <SectionHeader title="Edit Episode"/>
      <EditEpisodeForm episodeToEdit={state?.movieToEdit}/>
    </div>
  );
};

export default EditEpisodeSection;
