import { SUPER_ADMIN } from "@app/constants";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import { NotFound } from "@app/views";
import React, { useEffect } from "react";
// import { findAllInRenderedTree } from "react-dom/test-utils";
import { useLocation, Navigate } from "react-router-dom";

function PrivateRoute({
  component: Component,
  exclusivelyForSuperAdmin,
  ...rest
}) {
  const { user, setLocation } = useAuthContext();
  const { pathname } = useLocation();
  // alert(location.pathname);
  const ComponentToDisplay =
    user?.type !== SUPER_ADMIN && exclusivelyForSuperAdmin
      ? NotFound
      : Component;
  useEffect(() => {
    setLocation(pathname);

    return () => {
      // second
    };
  }, [pathname, setLocation, user]);

  return (
    <>
      {user ? (
        <ComponentToDisplay />
      ) : (
        <Navigate
          to={"/auth/signin"}
          state={{ from: pathname }}

          // replace
        />
      )}
    </>
  );
}

export default PrivateRoute;
