import * as React from "react";
const SvgSubscriptions = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 4h16v2H2V4Zm2-4h12v2H4V0Zm14 8H2c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2Zm0 10H2v-8h16v8ZM8 10.73v6.53L14 14l-6-3.27Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSubscriptions;
