// import axios from "axios";
import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Dndzone } from "./components";
import { ref, storage } from "@app/config/firebase.config";
import {
  getDownloadURL,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { toast } from "react-toastify";
import Toast from "@app/components/Toast";
// const storageRef = ref(storage, "epg_files");
const CSVInput = ({
  setFile,
  setUploading,
  setProgress,
  setUploadState,
  setUploadObject,
}) => {
  const onDrop = useCallback(
    async (files, fileRejections) => {
      // Do something with the files
      console.log({ file: typeof files[0], fileRejections });
      if (!!fileRejections?.length) {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            // if (err.code === "file-too-large") {
            //   setShowErrorModal(true);
            //   setImportFileSizeResponse(() => ({
            //     status: true,
            //     message: err?.message,
            //   }));
            // }

            if (err.code === "file-invalid-type") {
              toast.error(
                <Toast
                  message="You tried uploading a non-CSV file!"
                  toastType="info"
                />
              );
              // alert(
              //   "You tried to upload a file with a .txt extension, which is not supported. Please save the file as a .csv."
              // );
            }
          });
        });
      }
      if (files.length > 0 && fileRejections.length === 0) {
        // setIsDetails(false);
        const uploadTask = uploadBytesResumable(
          ref(storage, `epg_files/${files[0]?.name?.replace(/ /g, "_")}`),
          files[0]
        );
        // setUploadObject(uploadTask.pause);

        //  =========
        setFile(files[0]);
        setUploading(true);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // setTimeout(async () => {
            //   await uploadTask.pause();
            //   setTimeout(async () => {
            //     await uploadTask.resume();
            //   }, [6000]);
            // }, [3000]);
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log("Upload is " + progress + "% done");
            // console.log({ snapshot });
            setUploadState(snapshot.state);
            setProgress(progress);
            // switch (snapshot.state) {
            //   case "paused":
            //     console.log("Upload is paused");
            //     break;
            //   case "running":
            //     console.log("Upload is running");
            //     break;
            //   default:
            //     console.log({ snapshot });
            // }
          },
          (error) => {
            // Handle unsuccessful uploads
            // console.log({ error });
            toast.error(
              <Toast
                message={"Ooops! Seems you cancelled the upload."}
                toastType="error"
              />
            );
            // setUploadState("running");
            // setUploading(false);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            //   console.log("File available at", downloadURL);
            // });
            toast.success(
              <Toast
                message="csv file uploaded successfully!"
                toastType="success"
              />
            );
            setUploadState("running");
            setUploading(false);
          }
        );

        // =======

        // 'file' comes from the Blob or File API
        // await uploadBytes(
        //   ref(storage, `epg_files/${files[0]?.name?.replace(/ /g, "_")}`),
        //   files[0]
        // )
        //   .then((snapshot) => {
        //     toast.success(
        //       <Toast
        //         message="csv file uploaded successfully!"
        //         toastType="success"
        //       />
        //     );
        //   })
        //   .then(() => setUploading(false));
        // console.log({ filesss: files[0] });
        // dispatch(prospectActions.uploadCsv(files[0]));
      }
    },
    [setFile, setProgress, setUploading]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    // noClick: true,
    // noKeyboard: true,
    accept: {
      "text/csv": [".csv"],
    },
    onDrop,
    maxFiles: 1,
  });

  //   const handleCancelThumbnail = () => setFiles([]);
  //   useEffect(() => {
  //     // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
  //     return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  //   }, [files]);
  return (
    <div>
      <label htmlFor="upload" className="text-h2  leading-l7">
        Upload CSV file
      </label>
      {/* {!!files.length ? (
        <Thumbnail
          files={files}
          handleCancelThumbnail={handleCancelThumbnail}
        />
      ) : ( */}
      <Dndzone
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        isDragActive={isDragActive}
      />
      {/* )} */}
    </div>
  );
};

export default CSVInput;
