import InnerDashboardLayout from "@app/layouts/InnerDashboardLayout";
import React from "react";
import GenresSection from "./GenresSection";

const Genres = () => {
  return (
    <InnerDashboardLayout
      // counts={DASHBOARD_COUNTS}
      children={<GenresSection />}
    />
  );
};

export default Genres;
