// import axios from "axios";
import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Dndzone, Thumbnail } from "./components";

const ThumbnailInput = ({
  files,
  setFiles,
  showError,
  postThumbnail,
  customError,
}) => {
  const onDrop = useCallback(
    async (acceptedFiles) => {
      // Do something with the files
      let files = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles(files);

      // await generateUrl(formData);
    },
    [setFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop,
    maxFiles: 1,
  });

  const handleCancelThumbnail = () => setFiles([]);
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);
  return (
    <div>
      <label htmlFor="upload" className="text-h2  leading-l7">
        Thumbnail
      </label>
      {!!files.length ? (
        <>
          <Thumbnail
            files={files}
            handleCancelThumbnail={handleCancelThumbnail}
          />
          {postThumbnail}
        </>
      ) : (
        <Dndzone
          getInputProps={getInputProps}
          getRootProps={getRootProps}
          isDragActive={isDragActive}
        />
      )}
      {showError && (
        <p className="text-primary-03 text-h1">
          {customError
            ? customError
            : "Image poster for movie wasn't uploaded!"}
        </p>
      )}
    </div>
  );
};

export default ThumbnailInput;
