import classNames from "classnames";
import React from "react";

// <label class="inline-flex relative items-center mb-5 cursor-pointer">
//   <input type="checkbox" value="" class="sr-only peer">
//   <div class="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
//   <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Small toggle</span>
// </label>

// <label class="inline-flex relative items-center mb-5 cursor-pointer">
//   <input type="checkbox" value="" class="sr-only peer">
//   <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
//   <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Default toggle</span>
// </label>

// <label class="inline-flex relative items-center cursor-pointer">
//   <input type="checkbox" value="" class="sr-only peer">
//   <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
//   <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Large toggle</span>
// </label>
const Toggle = ({
  labelSize = "medium",
  color = "primary",
  disabled = false,
  loading = false,
  labelPosition = "right",
  gapSize = "medium",
  toggleSize = "medium",
  value,
  name,
  labelTitle,
  handleChange,
}) => {
  return (
    <label
      htmlFor={name}
      className={classNames(
        "relative items-left cursor-pointer flex-col gap-1 inline-block w-max",
        { "cursor-not-allowed": loading }
      )}
    >
      <input
        type="checkbox"
        class="sr-only peer"
        name={name}
        checked={value}
        id={name}
        onChange={handleChange}
      />
      {/* <div
        className={classNames("flex gap-5", {
          " flex-col": labelPosition === "bottom",
          " flex-col-reverse": labelPosition === "top",
          " flex-row": labelPosition === "right",
          " flex-row-reverse": labelPosition === "left",
          "gap-2": gapSize === "small",
          "gap-4": gapSize === "medium",
          "gap-6": gapSize === "extramedium",
          "gap-8": gapSize === "large",
          "text-h1 font-normal": labelSize === "small",
          "text-h2 font-normal": labelSize === "medium",
          "text-h3 font-normal": labelSize === "large",
        })}
      > */}
      {labelTitle && (
        <span className=" text-h2 leading-l7 text-black-07  inline-block text-left">
          {labelTitle}
        </span>
      )}
      <div
        className={classNames(
          "relative bg-neutral-05 peer-focus:outline-none after:-translate-x-1/4 peer-focus:ring-0 rounded-full peer-checked:after:translate-x-[calc(100%-25%)] peer-checked:after:border-white after:content-[''] after:absolute  after:bg-[#fff] after:border-[#ffffff] after:border after:rounded-full  after:transition-all peer-checked:bg-primary-04 after:shadow-small after:top-1/2 after:left-[0px] z-[3] after:-translate-y-1/2 w-full after:z-[3]",
          {
            "w-9 h-4 rounded-[30px] after:h-6 after:w-6 ":
              toggleSize === "medium",
            "w-14 h-7 rounded-[30px] after:h-9 after:w-9 ":
              toggleSize === "large",
          }
        )}
      ></div>
      {/* </div> */}
    </label>
  );
};

export default Toggle;
