import React from "react";
import SuspendedAdminsTable from "./SuspendedAdminsTable";

const tableHeader = [
  {
    name: "No",
    width: " w-[40px]",
  },
  { name: "Email", fraction: "threerth" },
  { name: "Status", fraction: "twoth" },
  { name: "Actions", fraction: "threeth" },
];

const SuspendedAdmins = ({ loading, setPageToShow, data }) => {
  return (
    <>
      <div className="w-full h-5" />
      <SuspendedAdminsTable
        tableBody={data}
        tableHeader={tableHeader}
        isLoading={loading}
      />
    </>
  );
};

export default SuspendedAdmins;
