import React from "react";
import Table from "@app/components/common/Table";
import classNames from "classnames";
import { Delete } from "@app/components/Icon/icons";
import ActionsButtonList from "@app/components/common/ActionsButtonList";
import StatusCard from "@app/components/common/StatusCard";
import { AWAITING_SIGNUP, PENDING_APPROVAL } from "@app/constants";
import { VanillaTickMark } from "@app/components/Icon/icons";

const getStatus = (status) => {
  if (status === AWAITING_SIGNUP) return "Yet to Signup";
  if (status === PENDING_APPROVAL) return "Pending";
};

const DeletedAdminsTable = ({ tableHeader, tableBody, isLoading }) => {
  const ACTIONS = [
    {
      icon: VanillaTickMark,
      action: "approve",
    }
    // { icon: Delete, action: "delete" },
  ];

  return (
    <Table
      emptyTableText={
        !!tableBody?.length ? null : "There are no deleted admins currently."
      }
      isLoading={isLoading}
    >
      <Table.Header data={tableHeader} />
      <Table.Body>
        {tableBody?.map((item, index) => (
          <tr className={classNames("h-16 ", { "bg-black-01": !(index % 2) })}>
            <td className="text-center">{index + 1}</td>

            <td className="  ">{item?.email}</td>
            <td className="">
              {<StatusCard status="deleted" text={getStatus(item.status)} />}
            </td>
            <td>{<ActionsButtonList items={ACTIONS} id={item.adminId} />}</td>
          </tr>
        ))}
      </Table.Body>
    </Table>
  );
};

export default DeletedAdminsTable;
