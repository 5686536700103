import { Upload } from '@app/components/Icon/icons';
import React from 'react'


const Dndzone = ({ getRootProps, getInputProps, isDragActive }) => {
    return (
      <div
        {...getRootProps({
          className:
            "w-full h-[200px]  border-[1px] border-neutral-02 rounded-[2px] flex justify-center items-center flex-col gap-1 cursor-pointer",
        })}
      >
        <input {...getInputProps({ id: "upload" })} />
        <span className="w-[55px] h-[50px] block">
          <Upload />
        </span>
        {isDragActive ? (
          <p>Drop the file here ...</p>
        ) : (
          <p>Drag 'n' drop a files here, or click to select file</p>
        )}
      </div>
    );
  };
  

export default Dndzone