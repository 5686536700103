import * as React from "react";
const SvgList = (props) => (
  <svg
    viewBox="0 0 31 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30.5 24.534V1.752c0-.902-.726-1.627-1.627-1.627H6.633A6.51 6.51 0 0 0 .126 6.634V28.33a6.51 6.51 0 0 0 6.509 6.51h22.239c.901 0 1.627-.726 1.627-1.628v-1.085c0-.508-.237-.97-.603-1.268-.285-1.044-.285-4.02 0-5.064.366-.292.603-.753.603-1.262ZM8.804 9.21c0-.223.183-.406.406-.406h14.374c.224 0 .407.183.407.406v1.356a.408.408 0 0 1-.407.407H9.21a.408.408 0 0 1-.406-.407V9.21Zm0 4.34c0-.224.183-.407.406-.407h14.374c.224 0 .407.183.407.407v1.356a.408.408 0 0 1-.407.406H9.21a.408.408 0 0 1-.406-.406V13.55Zm17.18 16.95H6.634c-1.2 0-2.17-.97-2.17-2.17 0-1.193.977-2.17 2.17-2.17h19.35c-.128 1.16-.128 3.18 0 4.34Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgList;
