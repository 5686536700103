import classNames from "classnames";
import React from "react";

const SectionHeader = ({ title, total, notSelected }) => {
  return (
    <div className="text-left flex items-center gap-2 text-[#161B25]">
      <h2
        className={classNames("typography-semibold-28px inline-block", {
          "text-neutral-02": notSelected,
        })}
      >
        {title}
      </h2>
      {total && (
        <p className="typography-normal-14px align-middle inline-block">
          Total: {total}
        </p>
      )}
    </div>
  );
};

export default SectionHeader;
