const { default: api } = require("./api");

const addLandingPageItem = (payload) =>
  api.post("/landingPageItems/add", payload);
const getLandingPageItem = ({ lastItemId, limit }) =>
  api.get(
    `/landingPageItems?limit=${limit}${
      !!lastItemId?.length ? `&lastItemId=${lastItemId}` : ""
    }`
  );

const deleteLandingPageItem = (id) =>
  api.post(`landingPageItems/delete`, { id });

export { addLandingPageItem, getLandingPageItem, deleteLandingPageItem };
