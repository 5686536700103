import api from "./api";

const loginAdmin = (user) => api.post("/Login", user).then((res) => res.data);
const createAdmin = (admin) =>
  api.post("/new/sign-up", admin).then((res) => res);
const resetPassword = (admin) => api.post("/reset-password", admin);
const forgotPassword = (payload) => api.post("/forgot-password", payload);
const resendOTP = ({ email }) =>
  api.post("/resend-otp", { email, type: "password_reset" });

export { createAdmin, loginAdmin, resetPassword, forgotPassword, resendOTP };
