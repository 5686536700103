// import api from "@app/api/api";
import {
  addLandingPageItem,
  deleteLandingPageItem,
  getLandingPageItem,
} from "@app/api/landingPageItem";
const {
  useInfiniteQuery,
  useQueryClient,
  useMutation,
} = require("react-query");

const useAddLandingItem = () => {
  const queryClient = useQueryClient();
  const { data, mutate, isLoading, isError, error } = useMutation({
    mutationFn: addLandingPageItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: "landing-items" });
    },
  });
  return { data, mutate, isLoading, isError, error };
};

const useInfiniteLandingItems = (id, limit) => {
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    isLoading,
    isError,
  } = useInfiniteQuery(
    "landing-items",
    ({ pageParam }) => {
      // console.log({ pageParam });
      return getLandingPageItem({ lastItemId: pageParam, limit });
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastItem, allItems) => {
        // console.log({ allItems });
        const data = allItems[allItems.length - 1]?.data;
        const last = data?.[data.length - 1];

        // console.log({ data, last });
        return last?.id;
      },
    }
  );
  return {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    isLoading,
    isError,
  };
};

const useDeleteLandingItem = () => {
  const queryClient = useQueryClient();
  const { data, mutate, isLoading, isError, error } = useMutation({
    mutationFn: deleteLandingPageItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: "landing-items" });
    },
  });
  return { data, mutate, isLoading, isError, error };
}

export { useInfiniteLandingItems, useAddLandingItem ,useDeleteLandingItem};
