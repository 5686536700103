import classNames from "classnames";
import React from "react";
import Skeleton from "../common/Skeleton";
// import { CirclePlus } from "../Icon/icons";

const DetailCard = ({
  name,
  value,
  className,
  icon: Icon,
  selected,
  isFetching,
  ...props
}) => {
  return (
    <div
      className={classNames(
        "px-4 py-4 w-max  flex gap-2 items-center bg-[#ffffff]",
        className,
        {
          "text-[#fffff] bg-primary-04": selected,
        }
      )}
      {...props}
    >
      <span
        className={classNames(
          "w-[60px] h-[60px] bg-secondary-06 flex items-center justify-center  rounded-[50%]",
          {
            "bg-primary-02": selected,
          }
        )}
      >
        <span className="w-[20px] h-[20px] text-black-01 flex justify-center items-center">
          <Icon />
        </span>
      </span>
      <div
        className={classNames("text-left ", {
          "text-[#ffffff]": selected,
          "text-[#161B25]": !selected,
        })}
      >
        <span className="font-normal text-h2 leading-l6 block">{name}</span>
        {isFetching ? (
          <Skeleton height="20px" width="150px" />
        ) : (
          <span className="font-medium leading-l5 block">{value} </span>
        )}
      </div>
    </div>
  );
};

export default DetailCard;
