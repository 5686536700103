import InnerDashboardLayout from '@app/layouts/InnerDashboardLayout'
import React from 'react'
import AddTvProgrammesSection from './AddTvProgrammesSection'

const AddTvProgrammes = () => {
  return (
    <InnerDashboardLayout children={<AddTvProgrammesSection/>}/>
  )
}

export default AddTvProgrammes