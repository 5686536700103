import React from "react";
import Sidebar from "@app/components/common/Sidebar";
import Header from "@app/components/common/Header";
import { SUPER_ADMIN, MANAGE_ADMINS } from "@app/constants";
import {
  Bell,
  Exit,
  FourRoundedSquares,
  Livetv,
  ManageAdmin,
  Movies,
  Settings,
  Subscriptions,
} from "@app/components/Icon/icons";
import { Outlet } from "react-router-dom";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";

const MenuItems = [
  {
    name: "Movies",
    link: "/movies",
    icon: Movies,
  },
  {
    name: "Manage genres",
    link: "/manage-genres",
    icon: FourRoundedSquares,
  },

  {
    name: "EPG",
    link: "/epg",
    icon: Livetv,
  },
  // {
  //   name: "Push Notifications",
  //   link: "/push-notifications",
  //   icon: Bell,
  // },
  {
    name: "Subscriptions",
    link: "/subscriptions",
    icon: Subscriptions,
  },
  // {
  //   name: "Settings",
  //   link: "/settings",
  //   icon: Settings,
  // },
  {
    name: MANAGE_ADMINS,
    link: "/manage-admins",
    icon: ManageAdmin,
  },
  {
    name: "Manage Home Page",
    link: "/manage-home-page",
    icon: Bell,
  },
  {
    name: "Logout",
    link: "/logout",
    icon: Exit,
    // icon: Settings,
  },
];

const DashboardLayout = () => {
  const {
    user: { type },
  } = useAuthContext();

  const MENU_ITEMS =
    type === SUPER_ADMIN
      ? MenuItems
      : MenuItems.filter((item) => item.name !== MANAGE_ADMINS);

  return (
    // <div className="flex gap-1 overflow-y-hidden h-screen">
    <div className="overflow-y-hidden h-screen flex gap-1">
      {/* //  <div className="h-screen "> */}
      {/* <div className="flex gap-1 overflow-y-auto flex-auto"> */}

      <div className="max-w-[260px] lg:w-[24.5vw] min-w-[250px] w-[29.5vw]  h-screen bg-[#ffffff] shrink-0 static">
        <Sidebar items={MENU_ITEMS} />
      </div>
      {/* <MainSection> */}

      <div className="flex flex-col flex-auto overflow-y-auto">
        <Header />
        {/* <section className="flex-auto overflow-y-auto flex flex-col"> */}
        <section className="flex-auto flex flex-col">
          <Outlet />
          {/* {children} */}
        </section>
      </div>
      {/* </div> */}
      {/* </MainSect/ion> */}
    </div>
  );
};

export default DashboardLayout;
