import createFactory from "./helpers/contextFactory";
import storage from "../storage";
import { loginAdmin } from "@app/api/authapi";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "@app/config/firebase.config";
import { toast } from "react-toastify";
import Toast from "@app/components/Toast";
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

const [AuthContext, useAuthContext] = createFactory();

export { useAuthContext };

const AuthProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [location, setLocation] = useState("/movies");
  const [user, setUser] = useState(() => {
    const token = storage.getToken();
    const email = storage.get("email");
    const type = storage.get("type");

    const isValid = token && email && type;

    if (isValid) {
      return { token, email, type };
    }

    return null;
  });

  const login = useCallback(
    async (value) => {
      const response = await loginAdmin(value);
      const { token } = response;
      const { type, email } = response?.admin;

      const authResponse = await signInWithCustomToken(auth, token);
   
      const { idToken, refreshToken } = authResponse?._tokenResponse;
      storage.set("id", authResponse?.user?.uid);
      storage.setToken(idToken);
      storage.set("ref_", refreshToken);
      storage.set("email", email);
      storage.set("type", type);

      // save to in app memory , when refreshed saved local data is retrieved
      // else log user out
      setUser({ email, type, token: idToken });
      toast.success(<Toast message="Login successful" toastType="success" />);
      navigate(location, { replace: true });
    },
    [location, navigate]
  );

  const logout = useCallback(() => {
    if (location === "/logout") setLocation("/movies");
    setUser(null);
    storage.clearToken();
    storage.clear("email");
    storage.clear("ref_");
    storage.clear("type");
    navigate("/auth/signin", { replace: true });
    queryClient.clear();
  }, [location, navigate, queryClient]);

  const value = useMemo(
    () => ({
      login,
      user,
      logout,
      setLocation,
    }),
    [login, logout, user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
