import { AUTH_IS_REQUIRED, INVALID_AUTH_MESSAGE } from "@app/constants";
import { useEffect } from "react";
import { useAuthContext } from "../contexts.js/AuthProvider";

const useErrorHandler = (useHook, _, id, limit, lastMovieId, action) => {
  const { logout } = useAuthContext();
  const {
    isLoading,
    isError,
    mutate,
    approveDeleteSuspend,
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useHook(id ? id : "", limit, lastMovieId, action);
  // console.log({ isError, error });

  useEffect(() => {
    if (
      isError &&
      error &&
      (error?.response?.data?.message === INVALID_AUTH_MESSAGE ||
        error?.response?.data?.message === AUTH_IS_REQUIRED ||
        error?.response?.data?.message === "Invalid auth token" ||
        error?.response?.data?.message === "Network Error") &&
      error?.response?.data?.statusCode === 401
    ) {
      logout();
    }
  }, [
    error,
    error?.response?.data?.message,
    error?.response?.data?.statusCode,
    isError,
    logout,
    limit,
    data,
  ]);
  return {
    isFetching,
    mutate,
    isLoading,
    approveDeleteSuspend,
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  };
};

export default useErrorHandler;
