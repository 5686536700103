import Button from "@app/components/common/Button";
import { Plus } from "@app/components/Icon/icons";

import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import { useErrorHandler } from "@app/utils/hooks";
import { useSubscriptions } from "@app/utils/hooks/reactQuery/subscription";

import React from "react";
import { Link } from "react-router-dom";
import SubscriptionsTable from "./SubscriptionsTable";

const tableHeader = [
  {
    name: "No",
    width: " w-[40px]",
  },
  { name: "Title", fraction: "twoth" },
  { name: "Description", fraction: "sixth" },
  { name: "Price(in Naira)", fraction: "twoth" },
  { name: "duration(in Weeks)", fraction: "twoth" },
  { name: "Actions", fraction: "threeth" },
];

const SubscriptionsSection = () => {
  const { logout } = useAuthContext();
  const { data, isLoading } = useErrorHandler(
    useSubscriptions,
    logout,
    ""
  );

  return (
    <>
      <div className="flex justify-between items-center w-full mb-6">
        <Link to="/subscriptions/add">
          <Button icon={Plus} iconPosition="left" letterCase="capitalize">
            Add Subscription
          </Button>
        </Link>
      </div>

      <section className="mt-6">
        <SubscriptionsTable
          tableBody={data}
          tableHeader={tableHeader}
          isLoading={isLoading}
        />
      </section>
    </>
  );
};

export default SubscriptionsSection;
