import * as React from "react";
const SvgMovies = (props) => (
  <svg
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 2.47 3.76 6H18v8H2V2.47ZM20 0h-4l2 4h-3l-2-4h-2l2 4h-3L8 0H6l2 4H5L3 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V0Z"
      fill="currentColor"
      fillOpacity={0.6}
    />
  </svg>
);
export default SvgMovies;
