// import Button from "@app/components/common/Button";
import Input from "@app/components/common/Input";
import * as Yup from "yup";
// import { Link } from "@app/components/Icon/icons";

import React, {  useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import Layout from "../Layout";
import { REGEX_EMAIL } from "@app/constants";
import AuthForm from "../components/AuthForm";
import { createAdmin } from "@app/api/authapi";
import { toast } from "react-toastify";
import Toast from "@app/components/Toast";
// import { storage } from "@app/utils";
// import { toast } from "react-toastify";
// import { ToastIconSuccess } from "@app/components/Icon/icons";

const INFO = [
  {
    type: "Referral Email",
    name: "referralEmail",
  },
  {
    type: "Your Email",
    name: "email",
  },
  {
    type: "Token",
    name: "token",
  },
  {
    type: "Full Name",
    name: "fullName",
  },
  {
    type: "Password",
    name: "password",
  },
];

const VALIDATION_SCHEMA = Yup.object({
  referralEmail: Yup.string()
    .trim()
    .matches(REGEX_EMAIL, "Email address is invalid")
    .required("Required"),
  email: Yup.string()
    .trim()
    .matches(REGEX_EMAIL, "Email address is invalid")
    .required("Required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long!")
    .required("Required"),
  fullName: Yup.string()
    .min(3, "Full Name must be at least 3 characters long!")
    .required("Required"),
  // token: Yup.string().email('Invalid email address').required('Required'),
});

const INITIAL_VALUES = {
  referralEmail: "",
  email: "",
  password: "",
  fullName: "",
  token: "",
};

const Signup = () => {
  const [isCreatingAdmin, setIsCreatingAdmin] = useState(false);

  const navigate = useNavigate();

  const onSubmit = async (values) => {
    setIsCreatingAdmin(true);
    createAdmin(values)
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(<Toast message={res.message} toastType="success" />);
        }
      })
      .catch((e) => console.log("error in signup page: ", e))
      .finally(() => {
        setIsCreatingAdmin(false);
        navigate("/auth/signin");
      });
  };

  return (
    <AuthForm
      // handleSubmit={formik.handleSubmit}
      isLoading={isCreatingAdmin}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
      initialValues={INITIAL_VALUES}
      belowButtonText={
        <>
          {" "}
          Have an account already ?{" "}
          <Link to="/auth/signin">
            <span className="text-primary-06 hover:underline hover:underline-offset-2">
              Sign in
            </span>
          </Link>
        </>
      }
      text="Sign Up"
      title="Sign Up"
    >
      {(formik) => {
        return INFO.map((item, index) => (
          <Input
            onChange={formik.handleChange}
            value={formik.values[item.name]}
            name={item.name}
            onBlur={formik.handleBlur}
            labelTitle={item.type}
            loading={isCreatingAdmin}
            placeholder={item.placeholder || "placeholder"}
            showIcon={item.name === "password"}
            variant="transparent"
            key={index}
            message={
              formik.touched[item.name] &&
              formik.errors[item.name] && {
                type: "error",
                value: formik.errors[item.name],
              }
            }
          />
        ));
      }}
    </AuthForm>
  );
};

export default Signup;
