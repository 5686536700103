import Button from "@app/components/common/Button";
import Input from "@app/components/common/Input";
import { REGEX_EMAIL } from "@app/constants";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import { useFormik } from "formik";
// import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { nanoid } from "nanoid";
import { useErrorHandler } from "@app/utils/hooks";
import { useAddAdmin } from "@app/utils/hooks/reactQuery/admin";
import { toast } from "react-toastify";
import Toast from "@app/components/Toast";
// import { Link } from "react-router-dom";

const validationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .matches(REGEX_EMAIL, "Email address is invalid.")
    .required("Email address field can't be empty."),
});

const AddAdmin = ({ setPageToShow }) => {
  const logout = useAuthContext();
  const { mutate, isLoading, data } = useErrorHandler(useAddAdmin, logout);
  // const [isLoading] = useState(false);
  const [token, setToken] = useState(null);
  const {
    user: { email },
  } = useAuthContext();

  useEffect(() => {
    if (data) {
      toast.success(<Toast message={data?.message} toastType="success" />);
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (value, { resetForm }) => {
      const payload = { ...value, superAdminEmail: email, token };
      if (token) {
        mutate(payload);
      }
      // resetForm();
    },
  });
  const handleTokenGenerate = (e) => {
    e.preventDefault();
    if (formik.values.email !== '' && REGEX_EMAIL.test(formik.values.email) ){

      // setToken("Jofndslafkjdflasjfjasl");
      setToken(nanoid());
    }
  };
  

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5">
        <Input
          name="email"
          labelTitle="Admin Email"
          value={formik.values.email}
          onChange={data ? () => {} : formik.handleChange}
          placeholder="JohnDoe@gmail.com"
          message={
            formik.touched.email &&
            formik.errors.email && {
              type: "error",
              value: formik.errors.email,
            }
          }
        />

        <Input
          // name="admin_email"
          readonly
          labelTitle="Token"
          value={token || ""}
          // onChange= {handleInput}
          // placeholder="JohnDoe@gmail.com"
          // disabled={true}
          className=" min-w-[300px]"
          elementToRight={
            <Button
              disabled={token || (!REGEX_EMAIL.test(formik.values.email))}
              // loading={isLoading}
              type="button"
              className="disabled:cursor-not-allowed"
              onClick={handleTokenGenerate}
            >
              {token ? "Share token" : "Generate Token"}
            </Button>
          }
          // disabled
        />
        <div className="h-4" />
        {data ? (
          <Button
            type="button"

            onClick={() => setPageToShow("pending")}
            className="w-[60%] mx-auto max-w-3xl "
          >
            Go to Pending Admins
          </Button>
        ) : (
          <Button
            loading={isLoading}
            type="submit"
            className="w-[60%] mx-auto max-w-3xl "
          >
            Add Admin
          </Button>
        )}
      </form>
    </div>
  );
};

export default AddAdmin;
