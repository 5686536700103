// import { SectionHeader } from "@app/components/section";
// import AddEpiso/
import InnerDashboardLayout from "@app/layouts/InnerDashboardLayout";
import React from "react";
import { AddEpisodeSection } from "./components";

const AddEpisode = () => {
  return <InnerDashboardLayout children={<AddEpisodeSection />} />;
};

export default AddEpisode;
