import React, { useState } from "react";
import AuthForm from "../components/AuthForm";
import Input from "@app/components/common/Input";
import * as Yup from "yup";
import { REGEX_EMAIL } from "@app/constants";
import { Link } from "react-router-dom";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";

const INFO = [
  {
    type: "Email",
    name: "email",
    placeholder: "JohnDoe@gmail.com",
  },
  {
    type: "Password",
    name: "password",
    placeholder: "Aa2wrtL_wi",
  },
];

const VALIDATION_SCHEMA = Yup.object({
  email: Yup.string()
    .trim()
    .matches(REGEX_EMAIL, "Email address is invalid")
    .required("Required"),
  password: Yup.string()
    .min(8, "Password must be 8 characters or more")
    .required("Required"),
});

const INITIAL_VALUES = {
  email: "",
  password: "",
  // remember_me: false,
};

const Signin = ({ setUser }) => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { login } = useAuthContext();
  // const [isLoading] = useState(false);

  const onSubmit = async (values) => {
    setIsLoggingIn(true);
    try {
      await login(values);
    } catch (error) {
      console.log({ error });
    }

    setIsLoggingIn(false);
  };

  return (
    <AuthForm
      validationSchema={VALIDATION_SCHEMA}
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      // handleSubmit={formik.handleSubmit}
      isLoading={isLoggingIn}
      text="Sign In"
      title="Sign In"
      belowButtonText={
        <>
          {" "}
          Don't have an account ?{" "}
          <Link to="/auth/signup">
            {" "}
            <span className="text-primary-06 hover:underline hover:underline-offset-2">
              Sign up
            </span>
          </Link>
        </>
      }
    >
      {(formik) => {
        return (
          <>
            {INFO.map((item, index) => (
              <Input
                onChange={formik.handleChange}
                value={formik.values[item.name]}
                name={item.name}
                onBlur={formik.handleBlur}
                labelTitle={item.type}
                loading={isLoggingIn}
                placeholder={item.placeholder || "placeholder"}
                showIcon={item.name === "password"}
                variant="transparent"
                key={index}
                message={
                  formik.touched[item.name] &&
                  formik.errors[item.name] && {
                    type: "error",
                    value: formik.errors[item.name],
                  }
                }
              />
            ))}
            <div className="">
              <Link to="/auth/forgot">
                <span className="hover:underline hover:underline-offset-2 text-[#060606] text-[12px]">
                  Forgot Password?
                </span>
              </Link>
            </div>
          </>
        );
      }}
    </AuthForm>
  );
};

export default Signin;
