// import CountCard from "@app/components/CountCard";
import { Link, List, Monitor, People } from "@app/components/Icon/icons";
import InnerDashboardLayout from "@app/layouts/InnerDashboardLayout";
// import Genres from "../Genres";
// import TvShowsSection from "@app/views/Dashboard/TvShowsSection";
import TvShowsSection from "@app/views/Dashboard/TvShowsSection";

const DASHBOARD_COUNTS = [
  {
    name: "Genre list",
    value: 20,
    icon: List,
  },
  {
    name: "Tv show list",
    value: 10,
    icon: Monitor,
  },
  {
    name: "Registered users",
    value: 10,
    icon: People,
  },
  {
    name: "Link share",
    value: 10,
    icon: Link,
  },
];

const Dashboard = () => {
  return (
    <InnerDashboardLayout
      counts={DASHBOARD_COUNTS}
      children={<TvShowsSection />}
    />
    // <section className="flex">
    //   <div className="flex gap-1 overflow-hidden shrink-0">
    //     {DASHBOARD_COUNTS.map((item) => (
    //       <CountCard name={item?.name} value={item?.value}  icon={item?.icon} className="flex-1" />
    //     ))}
    //   </div>
    //   <TvShowsSection />
    // </section>
  );
};

export default Dashboard;
