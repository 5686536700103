import { getDashboardData } from "@app/api/countapi";

const { useQuery } = require("react-query");



const useDashboardCount = () => {
  const { isFetching, data, isError, error, isLoading } = useQuery({
    queryKey: "dashboardCount",
    queryFn: getDashboardData,
    retry: false,
  });
  return { isFetching, data, isError, error, isLoading };
};
export {
  useDashboardCount,
};
