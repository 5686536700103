import * as React from "react";
const SvgPeople = (props) => (
  <svg
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M39.563 36.188v-3.376a6.75 6.75 0 0 0-5.063-6.53M29.438 36.188v-3.376a6.75 6.75 0 0 0-6.75-6.75h-13.5a6.75 6.75 0 0 0-6.75 6.75v3.376M27.75 6.032a6.75 6.75 0 0 1 0 13.078M15.938 19.313a6.75 6.75 0 1 0 0-13.5 6.75 6.75 0 0 0 0 13.5Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPeople;
