import React from "react";
import WapTVLogo from "@app/assets/images/wapTvLogo.png";
import { Link } from "react-router-dom";
// import WapTVLogo from "../../../assets/images/Logo.png";

const Logo = () => {
  return (
    <Link to="/">
      <figure>
        <img src={WapTVLogo} alt="Wap TV Logo" className="w-20 " />
      </figure>
    </Link>
  );
};

export default Logo;
