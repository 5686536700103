import Logo from "@app/components/common/Logo";
import React from "react";

const Layout = ({ children }) => {
  return (
    <section className="w-screen min-h-screen flex items-center justify-center flex-col">
      <div className="flex flex-col gap-5 items-center">
        <Logo />
        {children}
      </div>
    </section>
  );
};

export default Layout;
