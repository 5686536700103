// import CountCard from "@app/components/CountCard";
import {
  Views,
  List,
  Monitor,
  Movies as MoviesIcon,
} from "@app/components/Icon/icons";
import InnerDashboardLayout from "@app/layouts/InnerDashboardLayout";
import { useErrorHandler } from "@app/utils/hooks";
import { useDashboardCount } from "@app/utils/hooks/reactQuery/count";
// import SvgMovies from '@app/components/Icon/icons/Movies';
import React, { useMemo } from "react";
// import TvShowsSection from "../Dashboard/TvShowsSection";
import MoviesSection from "./MoviesSection";

const Movies = () => {
  const { data: dashboardCounts, isLoading: isLoadingDashboardCounts } =
    useErrorHandler(useDashboardCount);

  const MOVIE_COUNTS = useMemo(() => {
    return [
      {
        name: "Full Movie",
        value: dashboardCounts?.fullMovieCount,
        icon: List,
      },
      {
        name: "Series",
        value: dashboardCounts?.seriesCount,
        icon: MoviesIcon,
      },
      {
        name: "Users",
        value: dashboardCounts?.userCount,
        icon: Monitor,
      },
      {
        name: "Subscriptions",
        value: dashboardCounts?.subscriptionCount,
        icon: Views,
      },
    ];
  }, [dashboardCounts?.fullMovieCount, dashboardCounts?.seriesCount, dashboardCounts?.subscriptionCount, dashboardCounts?.userCount]);
  return (
    <InnerDashboardLayout
      counts={MOVIE_COUNTS}
      isFetching={isLoadingDashboardCounts}
      children={<MoviesSection />}
    />
  );
};

export default Movies;
