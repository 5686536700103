import ActionsButtonList from "@app/components/common/ActionsButtonList";
import Table from "@app/components/common/Table";
import { Delete, Pencil } from "@app/components/Icon/icons";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import { useGlobalStateContext } from "@app/utils/contexts.js/GlobalStateProvider";
import classNames from "classnames";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

const EpisodesTable = ({ isLoading, tableBody, tableHeader, seasonsExist }) => {
  const navigate = useNavigate();
  const { setState } = useGlobalStateContext();
  const ACTION_ITEMS = useMemo(
    () => [
      // { icon: Airplane, action: "send", onClick: (index) => {} },
      // {
      //   disabled: (type) => (type === "series" ? false : true),
      //   icon: Plus,
      //   action: "add",
      //   onClick: (seriesId) => {
      //     navigate(`/movies/add-episode/${seriesId}`);
      //   },
      // },
      { icon: Delete, action: "delete", onClick: (index) => {} },
      {
        icon: Pencil,
        action: "edit",
        onClick: (id) => {
          // alert(id);
          // console.log({
          //   id,
          //   tableBody,
          //   movies,
          //   movie: movies?.find((movie) => movie.id === id),
          // });
          setState({
            episodes: tableBody,
          });
          navigate(`edit/${id}`);
        },
      },
    ],
    [navigate, setState, tableBody]
  );

  return (
    <Table
      emptyTableText={
        !!tableBody?.length && !isLoading
          ? null
          : `There are ${
              seasonsExist ? "no episodes for this season" : "no seasons"
            } yet!`
      }
      isLoading={isLoading}
    >
      <Table.Header data={tableHeader} />
      <Table.Body>
        {tableBody?.map((item, index) => (
          <tr
            className={classNames("h-16  ", {
              "bg-black-01": !(index % 2),
              "cursor-pointer hover:scale-[101%] duration-200 ease-out":
                item.type === "series",
            })}
            key={`movies_${item.id}`}
          >
            <td className="text-center">{index + 1}</td>
            <td className="h-20 flex justify-start ">
              <div className="h-full aspect-[102/54] relative overflow-hidden align-middle">
                <img
                  src={item?.poster}
                  alt={item?.title}
                  className="absolute top-0 left-0 right-0 bottom-0 "
                />
              </div>
            </td>
            <td className="break-word"> {item?.title}</td>
            <td className=" overflow-hidden relative ">
              {/* <Tooltip message={item?.description}> */}
              <p className="multiline-ellipsis h-full ">{item?.description}</p>
              {/* </Tooltip> */}
            </td>
            {/* <td>{MOVIE_TYPE[item.typ/e]}</td> */}
            <td>
              <ActionsButtonList
                items={ACTION_ITEMS}
                id={item.id}
                type={item.type}
              />
            </td>
          </tr>
        ))}
      </Table.Body>
    </Table>
  );
};

export default EpisodesTable;
