import Form from "@app/components/common/Form";
import Input from "@app/components/common/Input";
import MultipleSelectBox from "@app/components/MultipleSelectBox";
import Select from "@app/components/Select";
import ThumbnailInput from "@app/components/ThumbnailInput";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import { useErrorHandler } from "@app/utils/hooks";
import { useGenres } from "@app/utils/hooks/reactQuery/genre";
import { useAddMovie } from "@app/utils/hooks/reactQuery/movie";
// import { isAllSelected } from "@testing-library/user-event/dist/types/event";
// import axios from "axios";
import classNames from "classnames";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { Toast } from "react-toastify/dist/components";
import Toast from "@app/components/Toast";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "@app/config/firebase.config";
import UploadController from "@app/components/UploadController";
// import * as Yup from "yup";

const FULL_MOVIE_INFORMATION = [
  {
    info_type: "Title",
    placeholder: "",
    name: "title",
  },
  {
    info_type: "Movie Link",
    placeholder: "",
    name: "movieUrl",
  },
  {
    info_type: "Preview Link",
    placeholder: "",
    name: "previewUrl",
  },
  {
    info_type: "Description",
    placeholder: "placedholllder",
    name: "description",
  },
  {
    info_type: "Runtime in seconds",
    placeholder: 3600,
    name: "movieLength",
  },
  {
    info_type: "Release Year",
    placeholder: new Date().getFullYear(),
    name: "yearOfRelease",
  },
  {
    info_type: "Genre",
    placeholder: "comedy",
    name: "genre",
  },
  {
    info_type: "Movie Rating",
    placeholder: "18",
    name: "viewRating",
  },
];

const RELEASE_STATUS = [
  {
    name: "Draft",
    value: "draft",
  },
  {
    name: "Released",
    value: "released",
  },
  {
    name: "Coming Soon",
    value: "comingSoon",
  },
];

const MOVIE_TYPES = [
  {
    name: "Full Movie",
    value: "fullMovie",
  },
  {
    name: "Series",
    value: "series",
  },
];

const FullMovieForm = ({ movieTypeDropdown }) => {
  const [isUploadingPoster, setIsUploadingPoster] = useState(false);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const { logout } = useAuthContext();
  const { data, isFetching, isError } = useErrorHandler(useGenres, logout);
  const [movieType, setMovieType] = useState(MOVIE_TYPES[0]);

  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState(RELEASE_STATUS[0]);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  // const [uploadState,] = useState("running");

  const {
    isLoading,
    mutate,
    data: MovieResponse,
  } = useErrorHandler(useAddMovie);
  const formik = useFormik({
    initialValues: {
      title: "",
      yearOfRelease: "",
      description: "",
      movieLength: "",
      movieUrl: "",
      previewUrl: "",
      viewRating: "",
      isPremium: false,
    },
    // validationSchema,

    onSubmit: async (values) => {
      let payload = {
        ...values,
        movieLength: parseInt(values.movieLength),
        genres: selectedGenres.map((item) => item.genreSlug),
        type: movieType.value,
        status: status.value,
        poster: "",
        viewRating: `${values.viewRating}+`,
      };

      if (!files[0]) {
        setShowError(true);
      }

      if (files[0]) {
        setShowError(false);
        setIsUploadingPoster(true);
        const uploadTask = uploadBytesResumable(
          ref(storage, `movie_thumbnails/${files[0]?.name}`),
          files[0]
        );

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log("Upload is " + progress + "% done");
            // console.log({ snapshot });
            // setUploadState(snapshot.state);
            setProgress(progress);
          },
          (error) => {
            mutate(payload);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setIsUploadingPoster(false);
              payload = { ...payload, poster: downloadURL };
              mutate(payload);
            });
          }
        );

        // mutate(payload);
      }
    },
  });

  useEffect(() => {
    if (MovieResponse && !isLoading) {
      toast.success(
        <Toast message="Movie uploaded successfully!" toastType="success" />
      );
      navigate("/movies", { replace: true });
    }

    return () => {};
  }, [MovieResponse, isLoading, navigate]);

  useEffect(() => {
    if (files[0]) setShowError(false);
  }, [files]);
  return (
    <Form
      handleSubmit={formik.handleSubmit}
      buttonText="Send"
      handleToggle={formik.handleChange}
      formClassName="mb-[300px]"
      toggleValue={formik.values.isPremium}
      toggleLabel="Premium"
      toggleName="isPremium"
      isLoading={isUploadingPoster || isLoading}
    >
      <Select
        handleSelectedItem={setMovieType}
        items={MOVIE_TYPES}
        selectedItem={movieType}
        className="w-[60%] max-w-[300px]"
      />

      {FULL_MOVIE_INFORMATION.map((item, index) => {
        if (item.name === "genre") {
          return (
            <MultipleSelectBox
              disabled={isFetching || isError}
              list={data || []}
              handleSelectedItems={setSelectedGenres}
              labelText="Genre"
              isFetching={isFetching}
            />
          );
        }
        return (
          <Input
            labelTitle={item.info_type}
            name={item.name}
            value={formik.values[item.name]}
            placeholder={item.placeholder || "placeholder"}
            showIcon={item.name === "password"}
            variant="transparent"
            key={index}
            message={
              formik.touched[item.name] &&
              formik.errors[item.name] && {
                type: "error",
                value: formik.errors[item.name],
              }
            }
            textarea={item.name === "description"}
            onChange={formik.handleChange}
            className={classNames({
              "w-[60%] max-w-[300px]":
                item.name === "viewRating" ||
                item.name === "yearOfRelease" ||
                item.name === "movieLength",
            })}
          />
        );
      })}

      <Select
        labelText="Release Status"
        handleSelectedItem={setStatus}
        items={RELEASE_STATUS}
        selectedItem={status}
        className="w-[60%] max-w-[300px]"
      />

      <ThumbnailInput
        files={files}
        setFiles={setFiles}
        showError={showError}
        postThumbnail={
          <UploadController
            progress={progress}
            showButtons={false}
            size="small"
          />
        }
      />
    </Form>
  );
};

export default FullMovieForm;
