import React from "react";
import Logo from "@app/components/common/Logo";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

const Sidebar = ({ items }) => {
  const { pathname } = useLocation();

  return (
    <nav className="w-full h-full p-5">
      <Logo />
      <ul className="space-y-2 my-10">
        {items.map((item, index) => (
          <Link to={item.link} key={index}>
            <li
              className={classNames(
                "flex gap-3 items-center  hover:bg-grey-200 rounded-[12px] mb-2 py-3 px-5 ",
                {
                  "text-black-01 bg-primary-06":
                    pathname.includes(item.link) ||
                    (pathname === "/" && item.link === "/movies"),
                  "hover:bg-[#AAA]/20":
                    pathname === "/"
                      ? pathname === "/" && item.link !== "/movies"
                      : !pathname.includes(item.link),
                }
              )}
            >
              <span className="w-5 h-5 flex items-center justify-center text-inherit">
                {<item.icon />}
              </span>
              <p>{item.name}</p>
            </li>
          </Link>
        ))}
      </ul>
    </nav>
  );
};

export default Sidebar;
