import Button from "@app/components/common/Button";
import Skeleton from "@app/components/common/Skeleton";
import { Plus } from "@app/components/Icon/icons";
import { SectionHeader } from "@app/components/section";
import Select from "@app/components/Select";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import { useGlobalStateContext } from "@app/utils/contexts.js/GlobalStateProvider";
import { useErrorHandler } from "@app/utils/hooks";
import { useEpisodes } from "@app/utils/hooks/reactQuery/movie";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import EpisodesTable from "./EpisodesTable";

const EpisodesSection = () => {
  const { logout } = useAuthContext();
  const { movieId } = useParams();
  const { state: movieToEdit } = useGlobalStateContext();
  const [seasonsObject, setSeasonsObject] = useState({});
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const { data, error, isLoading } = useErrorHandler(
    useEpisodes,
    logout,
    movieToEdit?.id || movieId
  );

  // console.log({ data });
  const tableHeader = [
    {
      name: "No",
      // fration: "oneth",
      width: " w-[34px]",
    },
    { name: "Thumbnail", fraction: "twoth" },
    { name: "Name", fraction: "oneth" },
    { name: "Description", fraction: "sixth" },
    { name: "Actions", fraction: "twoth" },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.data) {
      // alert("inside");
      const seasonsWithSortedOutEpisodes = {};
      const SEASONS = Object.keys(data.data).sort((a, b) => a - b);
      const TRANSFORMED_SEASONS = SEASONS.map((season) => ({
        name: `Season ${season}`,
        value: season,
      }));

      setSelectedSeason(TRANSFORMED_SEASONS[0]);
      setSeasons(TRANSFORMED_SEASONS);
      SEASONS.forEach(
        (item) =>
          (seasonsWithSortedOutEpisodes[item] = data.data[item].sort(
            (firstEpisode, secondEpisode) =>
              firstEpisode?.episodeNumber - secondEpisode?.episodeNumber
          ))
      );
      setSeasonsObject(seasonsWithSortedOutEpisodes);
    }
    if (!isLoading && error) {
      navigate("/movies");
    }
  }, [data, error, isLoading, navigate]);

  // console.log({ data });
  //   console.log({ data, error, isLoading });
  return (
    <div>
      <SectionHeader
        title={
          <>
            Episodes for the movie:{" "}
            {
              //   movieToEdit?.title ||
              isLoading ? <Skeleton height="24px" width="300px" /> : data?.title
            }
          </>
        }
      />
      {
        <div className="flex items-center justify-between">
          <Link to={`/movies/add-episode/${movieId}`}>
            <Button icon={Plus} iconPosition="left" className="my-2">
              Add Episode
            </Button>
          </Link>
          <div className="max-w-[300px] flex-grow my-3">
            <Select
              handleSelectedItem={setSelectedSeason}
              items={seasons}
              selectedItem={selectedSeason}
              // labeflText="Seasons"
              isLoading={isLoading}
              itemsExist={!!seasons.length}
            />
          </div>
        </div>
      }
      <section className="mt-6">
        <EpisodesTable
          tableBody={seasonsObject[selectedSeason?.value]}
          tableHeader={tableHeader}
          infiniteScroll
          seasonsExist={!!seasons.length}
          //   limit={limit.value}
          isLoading={isLoading}
        />
      </section>
    </div>
  );
};

export default EpisodesSection;
