import classNames from "classnames";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerWrapper = ({
  date,
  handleDateChange,
  handleDateSelect,
  loading,
}) => {
  return (
    <div className="w-[60%] max-w-[300px]">
      <label
        htmlFor="dateReleased"
        className="text-h2 leading-l7 "
      >
        Release Date
      </label>
      <DatePicker
        className={classNames(
          " py-[8px] px-4 rounded-[4px] cursor-pointer border-neutral-02 border-[1px] outline-[0px] outline-secondary-06  focus:border-secondary-06 placeholder:text-h2 focus:caret-secondary-06  text-black-04 relative w-full",
          {
            "cursor-not-allowed": loading,
          }
        )}
        wrapperClassName="w-full"
        selected={date}
        onSelect={handleDateSelect} //when day is clicked
        onChange={handleDateChange} //only when value has changed
        // isClearable
        placeholderText="MM/DD/YYYY"
        showPopperArrow={false}
        id="dateReleased"
      />
    </div>
  );
};

export default DatePickerWrapper;
