import InnerDashboardLayout from "@app/layouts/InnerDashboardLayout";
import React from "react";
// import ManageHomePage from ".";
// import GenresSection from "./GenresSection";
import ManageHomePageSection from "./ManageHomePageSection";

const ManageHomePage = () => {
  return (
    <InnerDashboardLayout
      // counts={DASHBOARD_COUNTS}
      children={<ManageHomePageSection />}
    />
  );
};

export default ManageHomePage;
