import React, { useMemo } from "react";
import Table from "@app/components/common/Table";
import classNames from "classnames";
import StatusCard from "@app/components/common/StatusCard";
import { Delete, Pencil } from "@app/components/Icon/icons";
import { useNavigate } from "react-router-dom";
import ActionsButtonList from "@app/components/common/ActionsButtonList";
import SvgDelete from "@app/components/Icon/icons/Delete";
import { useErrorHandler } from "@app/utils/hooks";
import { useDeleteGenre } from "@app/utils/hooks/reactQuery/genre";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Toast from "@app/components/Toast";
import { SUCCESS_STATUSCODE_200 } from "@app/constants";

const getStatus = (status) => {
  if (status) return "active";
  return "inactive";
};
const GenresTable = ({ tableHeader, tableBody, isLoading }) => {
  const navigate = useNavigate();
  const {
    mutate,
    data,
    isLoading: isDeleting,
    isError,
  } = useErrorHandler(useDeleteGenre);
  // console.log({ data, isLoading, isError });

  const ACTION_ITEMS_1 = useMemo(
    () => [
      {
        icon: Pencil,
        action: "edit",
        onClick: (item) =>
          navigate(
            `edit?genre=${item.genreTitle}&slug=${
              item.genreSlug
            }&active=${Number(item.active)}`
          ),
      },
      {
        icon: SvgDelete,
        action: "delete",
        onClick: (item) => {
          // console.log({ slug: item?.genreSlug });
          mutate(item?.genreSlug);
        },
        // navigate(
        //   `edit?genre=${item.genreTitle}&slug=${
        //     item.genreSlug
        //   }&active=${Number(item.active)}`
        // ),
      },
    ],
    [mutate, navigate]
  );

  useEffect(() => {
    if (
      data &&
      !isDeleting &&
      !isError &&
      data.statusCode === SUCCESS_STATUSCODE_200
    ) {
      toast.success(<Toast message={data?.message} toastType="success" />, {
        toastId: "delete-genre-success",
      });
    }
  }, [data, isDeleting, isError, isLoading]);

  const ACTION_ITEMS_2 = useMemo(
    () => [{ icon: Delete, action: "delete", onClick: (index) => {} }],
    []
  );

  const handleStatusClick = () => {};
  return (
    <Table
      isLoading={isLoading}
      emptyTableText={
        !!tableBody?.length && !isLoading ? null : "There are no genres yet!"
      }
    >
      <Table.Header data={tableHeader} />

      <Table.Body>
        {tableBody?.map((item, index) => (
          <tr
            className={classNames("h-16 ", { "bg-black-01": !(index % 2) })}
            key={index}
          >
            <td className="text-center">{index + 1}</td>
            <td className="">{item?.genreTitle}</td>
            <td>{item?.genreSlug}</td>
            <td>
              {" "}
              <StatusCard
                status={getStatus(item?.active)}
                handleClick={handleStatusClick}
                genre={item}
              />
            </td>

            <td className="text-center">
              <ActionsButtonList
                items={ACTION_ITEMS_1}
                id={item}
                loading={isDeleting}
              />
            </td>
            {/* <td className="">
              <ActionsButtonList items={ACTION_ITEMS_2} id={item} />
            </td> */}
          </tr>
        ))}
      </Table.Body>
    </Table>
  );
};

export default GenresTable;
