import React, { useEffect, useMemo } from "react";
import Table from "@app/components/common/Table";
import classNames from "classnames";
// import StatusCard from "@app/components/common/StatusCard";
import { Delete } from "@app/components/Icon/icons";
import { useNavigate } from "react-router-dom";
import ActionsButtonList from "@app/components/common/ActionsButtonList";
import useCopyToClipboard from "@app/utils/hooks/useCopyToClipboard";
import { useErrorHandler } from "@app/utils/hooks";
import { useDeleteLandingItem } from "@app/utils/hooks/reactQuery/landingItems";
import { toast } from "react-toastify";
import Toast from "@app/components/Toast";
// import ManageHomePageTable from ".";


const ManageHomePageTable = ({ tableHeader, tableBody, isLoading }) => {
  // const [copiedText, copy] = useCopyToClipboard();
  // const navigate = useNavigate();

  const {
    mutate,
    data,
    isLoading: isDeletingLandingPageItem,
  } = useErrorHandler(useDeleteLandingItem);

  const ACTION_ITEMS = useMemo(
    () => [
      {
        icon: Delete,
        action: "delete",
        onClick: (id) => mutate(id),
      },
    ],
    [mutate]
  );

  useEffect(() => {
    if (data && !isDeletingLandingPageItem) {
      toast.success(
        <Toast toastType={"success"} message="Item deleted successfully" />,
        {
          toastId: "toast-success-delete-landing-page-item",
        }
      );
    }

    return () => {};
  }, [data, isDeletingLandingPageItem]);

  return (
    <Table
      isLoading={isLoading}
      emptyTableText={
        !!tableBody?.length && !isLoading
          ? null
          : "There are no landing page items yet!"
      }
    >
      <Table.Header data={tableHeader} />

      <Table.Body>
        {tableBody?.map((item, index) => (
          <tr
            className={classNames("h-16  ", {})}
            key={`item_${item?.id}`}
            onClick={() => {
              //  if (item.type === "series") {
              //    setState(item);
              //    navigate(`/movies/${item.id}/episodes`);
              //  }
            }}
          >
            <td className="text-center ">{index + 1}</td>
            <td className="h-28 flex justify-start ">
              <div className="h-full aspect-[150/74] relative overflow-hidden align-middle">
                <img
                  src={item?.imageUrl}
                  alt={item?.title}
                  className="absolute top-0 left-0 right-0 bottom-0 "
                />
              </div>
            </td>
            <td className="break-word"> {item?.title}</td>

            <td className=" overflow-hidden relative ">
              {/* <Tooltip message={item?.description}> */}
              <p className="multiline-ellipsis h-full ">{item?.description}</p>
              {/* </Tooltip> */}
            </td>
            {/* <td>{MOVIE_TYPE[item.type]}</td> */}
            <td className="">
              <ActionsButtonList
                items={ACTION_ITEMS}
                id={item.id}
                type={item.type}
                loading={isDeletingLandingPageItem}
              />
            </td>
          </tr>
        ))}
      </Table.Body>
    </Table>
  );
};

export default ManageHomePageTable;
