import api from "./api";

const getAllGenres = (user) => api.get("/all-genres").then((res) => res.data);
const addGenre = (user) => api.post("/add-genre", user).then((res) => res);
const editGenre = (modifiedGenre) =>
  api.post("/edit-genre", modifiedGenre).then((res) => res);
const deleteGenre = (genreSlug) => {
  return api.delete("/delete-genre", { data: { genreSlug } });
};

export { getAllGenres, addGenre, editGenre, deleteGenre };
