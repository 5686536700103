import {
  addGenre,
  getAllGenres,
  editGenre,
  deleteGenre,
} from "@app/api/genreapi";

const { useQuery, useMutation, useQueryClient } = require("react-query");

// const "genres" = ["genres"];

const useGenres = () => {
  const { isFetching, data, isError, error, isLoading } = useQuery({
    queryKey: "genres",
    queryFn: getAllGenres,
    retry: false,
  });
  return { isFetching, data, isError, error, isLoading };
};

const useEditGenre = (slug) => {
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, isLoading } = useMutation({
    mutationFn: editGenre,
    mutationKey: "genres",

    onMutate: async (genreToUpdate) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: "genres" });

      // Snapshot the previous value
      const previousGenres = await queryClient.getQueryData("genres");
      // console.log({ previousGenres });
      // Optimistically update to the new value
      queryClient.setQueryData("genres", (old) => {
        const updatedGenres = old.map((item) => {
          if (item.genreSlug === genreToUpdate.genreSlug) {
            return genreToUpdate;
          }
          return item;
        });
        // console.log({ updatedGenres });
        return updatedGenres;
      });

      // Return a context object with the snapshotted value
      return { previousGenres };
    },
    // // If the mutation fails,
    // // use the context returned from onMutate to roll back
    onError: (err, genreToUpdate, context) => {
      queryClient.setQueryData("genres", context.previousGenres);
    },
    // // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: "genres" });
    },
    // onSuccess: () => {
    //   // Invalidate and refetch
    //   queryClient.invalidateQueries({ queryKey: "genres" });
    // },
  });
  return { mutate, isLoading, data, isError, isSuccess };
};
const useAddGenre = () => {
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, isLoading } = useMutation({
    mutationFn: addGenre,
    // mutationKey: ['genres' , slug],
    // onMutate: async (newGenre) => {
    //   // Cancel any outgoing refetches
    //   // (so they don't overwrite our optimistic update)
    //   await queryClient.cancelQueries({ queryKey: "genres" });

    //   // Snapshot the previous value
    //   const previousGenres = await queryClient.getQueryData("genres");
    //   console.log({ previousGenres });
    //   // Optimistically update to the new value
    //   queryClient.setQueryData("genres", (old) => [...old, newGenre]);

    //   // Return a context object with the snapshotted value
    //   return { previousGenres };
    // },
    // // If the mutation fails,
    // // use the context returned from onMutate to roll back
    // onError: (err, newGenre, context) => {
    //   queryClient.setQueryData("genres", context.previousGenres);
    // },
    // // Always refetch after error or success:
    // onSettled: () => {
    //   queryClient.invalidateQueries({ queryKey:"genres" });
    // },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: "genres" });
    },
  });
  return { mutate, isLoading, data, isError, isSuccess };
};

const useDeleteGenre = () => {
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, isLoading } = useMutation({
    mutationFn: deleteGenre,
    // mutationKey: ['genres' , slug],
    onMutate: async (slug) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: "genres" });

      // Snapshot the previous value
      const previousGenres = await queryClient.getQueryData("genres");
      // const updatedGenres = await previousGenres.filter(item => item.slug !== slug)
      // console.log({ previousGenres });
      
      // Optimistically update to the new value
      queryClient.setQueryData("genres", (old) =>
        old.filter((item) => item.genreSlug !== slug)
      );

      // Return a context object with the snapshotted value
      return { previousGenres };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newGenre, context) => {
      queryClient.setQueryData("genres", context.previousGenres);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: "genres" });
    },
  });

  return { mutate, data, isError, isSuccess, isLoading };
};

export { useGenres, useEditGenre, useAddGenre, useDeleteGenre };
