import React from "react";

import DeletedAdminsTable from "./DeletedAdminsTable";

const tableHeader = [
  {
    name: "No",
    // fration: "oneth",
    width: " w-[40px]",
  },
  { name: "Email", fraction: "threerth" },
  //   { name: "Name", fraction: "twoth" },
  //   { name: "Description", fraction: "fourth" },
  { name: "Status", fraction: "twoth" },
  { name: "Actions", fraction: "threeth" },
];

// const tableBody = [
//   {
//     image:
//       "https://imgs.search.brave.com/sOyCmrSvw8ywoZqiR88zF5ZnFwjTrZz-bOX2eqOEdLU/rs:fit:711:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5y/eHhSMWlaRWlyR2xu/TTFBbnNxMUVnSGFF/OCZwaWQ9QXBp ",

//     email: "JognJoggnDoeeee@gmail.com",
//     status: "pending",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/sOyCmrSvw8ywoZqiR88zF5ZnFwjTrZz-bOX2eqOEdLU/rs:fit:711:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5y/eHhSMWlaRWlyR2xu/TTFBbnNxMUVnSGFF/OCZwaWQ9QXBp ",

//     email: "JognJoggnDoeeee@gmail.com",
//     status: "pending",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/sOyCmrSvw8ywoZqiR88zF5ZnFwjTrZz-bOX2eqOEdLU/rs:fit:711:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5y/eHhSMWlaRWlyR2xu/TTFBbnNxMUVnSGFF/OCZwaWQ9QXBp ",

//     email: "JognJoggnDoeeee@gmail.com",
//     status: "pending",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/sOyCmrSvw8ywoZqiR88zF5ZnFwjTrZz-bOX2eqOEdLU/rs:fit:711:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5y/eHhSMWlaRWlyR2xu/TTFBbnNxMUVnSGFF/OCZwaWQ9QXBp ",

//     email: "JognJoggnDoeeee@gmail.com",
//     status: "pending",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/sOyCmrSvw8ywoZqiR88zF5ZnFwjTrZz-bOX2eqOEdLU/rs:fit:711:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5y/eHhSMWlaRWlyR2xu/TTFBbnNxMUVnSGFF/OCZwaWQ9QXBp ",

//     email: "JognJoggnDoeeee@gmail.com",
//     status: "pending",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/sOyCmrSvw8ywoZqiR88zF5ZnFwjTrZz-bOX2eqOEdLU/rs:fit:711:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5y/eHhSMWlaRWlyR2xu/TTFBbnNxMUVnSGFF/OCZwaWQ9QXBp ",

//     email: "JognJoggnDoeeee@gmail.com",
//     status: "pending",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/sOyCmrSvw8ywoZqiR88zF5ZnFwjTrZz-bOX2eqOEdLU/rs:fit:711:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5y/eHhSMWlaRWlyR2xu/TTFBbnNxMUVnSGFF/OCZwaWQ9QXBp ",

//     email: "JognJoggnDoeeee@gmail.com",
//     status: "pending",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/sOyCmrSvw8ywoZqiR88zF5ZnFwjTrZz-bOX2eqOEdLU/rs:fit:711:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5y/eHhSMWlaRWlyR2xu/TTFBbnNxMUVnSGFF/OCZwaWQ9QXBp ",

//     email: "JognJoggnDoeeee@gmail.com",
//     status: "pending",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/sOyCmrSvw8ywoZqiR88zF5ZnFwjTrZz-bOX2eqOEdLU/rs:fit:711:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5y/eHhSMWlaRWlyR2xu/TTFBbnNxMUVnSGFF/OCZwaWQ9QXBp ",

//     email: "JognJoggnDoeeee@gmail.com",
//     status: "pending",
//   },
// ];

const DeletedAdmins = ({ loading, data }) => {
  return (
    <>
      <div className="w-full h-5" />
      {/* <TvListTable tableHeader={tableHeader} tableBody={tableBody} /> */}
      <DeletedAdminsTable
        tableBody={data}
        tableHeader={tableHeader}
        isLoading={loading}
      />
    </>
  );
};

export default DeletedAdmins;
