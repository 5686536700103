import Button from "@app/components/common/Button";
import Input from "@app/components/common/Input";
// import Toggle from "@app/components/common/Toggle";
import Toast from "@app/components/Toast";
import { useErrorHandler } from "@app/utils/hooks";
import { useAddGenre } from "@app/utils/hooks/reactQuery/genre";
import { useFormik } from "formik";
import React, { useEffect } from "react";
// import { findAllInRenderedTree } from "react-dom/test-utils";
import { toast } from "react-toastify";
import * as Yup from "yup";

const validationSchema = Yup.object({
  genreTitle: Yup.string().trim().required("Genre name field can't be empty!"),
  // genreSlug: Yup.string().trim().required("Genre slug field can't be empty!"),
});
const initialValues = {
  // genreSlug: "",
  genreTitle: "",
};
const AddGenreSection = () => {
  const { isLoading, mutate, data } = useErrorHandler(useAddGenre);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => mutate(values),
    validationSchema,
  });
  useEffect(() => {
    // alert(isLoading);
    if (!isLoading && data) {
      toast.success(<Toast message={data.message} toastType="success" />);
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  return (
    <form className="flex flex-col gap-5" onSubmit={formik.handleSubmit}>
      <Input
        labelTitle="Genre name"
        placeholder="Comedy"
        name="genreTitle"
        value={formik.values.genreTitle}
        onChange={formik.handleChange}
        message={
          formik.touched.genreTitle &&
          formik.errors.genreTitle && {
            type: "error",
            value: formik.errors.genreTitle,
          }
        }
      />
      {/* <Input
        labelTitle="Genre slug"
        placeholder="/slug-value"
        name="genreSlug"
        // value="dsfdfdsaf"
        value={formik.values.genreSlug}
        onChange={formik.handleChange}
        message={
          formik.touched.genreSlug &&
          formik.errors.genreSlug && {
            type: "error",
            value: formik.errors.genreSlug,
          }
        }
      /> */}
      {/* <Toggle
        handleChange={formik.handleChange}
        name="active"
        value={formik.values.active}
        labelTitle="Status"
      /> */}
      <Button
        className="w-[80%] mx-auto min-w-[280px]"
        loading={isLoading}
        type="submit"
      >
        Add Genre
      </Button>
    </form>
  );
};

export default AddGenreSection;
