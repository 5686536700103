import * as React from "react";
const SvgPencil = (props) => (
  <svg
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m8.498 4.849.669.669-6.59 6.59H1.91v-.67l6.589-6.59ZM11.116.47a.728.728 0 0 0-.51.21l-1.33 1.332 2.727 2.727 1.331-1.331a.724.724 0 0 0 0-1.026L11.632.682a.714.714 0 0 0-.516-.211ZM8.498 2.79.454 10.834v2.728h2.727l8.044-8.044-2.727-2.727Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgPencil;
