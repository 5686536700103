// import Skeleton from "@app/components/common/Skeleton";
import Button from "@app/components/common/Button";
import { Plus } from "@app/components/Icon/icons";
// import { SectionHeader } from "@app/components/section";
import React from "react";
import ApprovedAdminsTable from "./ApprovedAdminsTable";
// import TvListTable from "../components/TvListTable";

const tableHeader = [
  {
    name: "No",
    // fration: "oneth",
    width: " w-[40px]",
  },
  { name: "Email", fraction: "threerth" },
  //   { name: "Name", fraction: "twoth" },
  //   { name: "Description", fraction: "fourth" },
  { name: "Status", fraction: "twoth" },
  { name: "Actions", fraction: "threeth" },
];

// const tableBody = [
//   {
//     image:
//       "https://imgs.search.brave.com/sOyCmrSvw8ywoZqiR88zF5ZnFwjTrZz-bOX2eqOEdLU/rs:fit:711:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5y/eHhSMWlaRWlyR2xu/TTFBbnNxMUVnSGFF/OCZwaWQ9QXBp ",

//     email: "JognJoggnDoeeee@gmail.com",
//     status: "active",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/sOyCmrSvw8ywoZqiR88zF5ZnFwjTrZz-bOX2eqOEdLU/rs:fit:711:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5y/eHhSMWlaRWlyR2xu/TTFBbnNxMUVnSGFF/OCZwaWQ9QXBp ",

//     email: "JognJoggnDoeeee@gmail.com",
//     status: "active",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/sOyCmrSvw8ywoZqiR88zF5ZnFwjTrZz-bOX2eqOEdLU/rs:fit:711:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5y/eHhSMWlaRWlyR2xu/TTFBbnNxMUVnSGFF/OCZwaWQ9QXBp ",

//     email: "JognJoggnDoeeee@gmail.com",
//     status: "active",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/sOyCmrSvw8ywoZqiR88zF5ZnFwjTrZz-bOX2eqOEdLU/rs:fit:711:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5y/eHhSMWlaRWlyR2xu/TTFBbnNxMUVnSGFF/OCZwaWQ9QXBp ",

//     email: "JognJoggnDoeeee@gmail.com",
//     status: "active",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/sOyCmrSvw8ywoZqiR88zF5ZnFwjTrZz-bOX2eqOEdLU/rs:fit:711:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5y/eHhSMWlaRWlyR2xu/TTFBbnNxMUVnSGFF/OCZwaWQ9QXBp ",

//     email: "JognJoggnDoeeee@gmail.com",
//     status: "active",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/sOyCmrSvw8ywoZqiR88zF5ZnFwjTrZz-bOX2eqOEdLU/rs:fit:711:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5y/eHhSMWlaRWlyR2xu/TTFBbnNxMUVnSGFF/OCZwaWQ9QXBp ",

//     email: "JognJoggnDoeeee@gmail.com",
//     status: "active",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/sOyCmrSvw8ywoZqiR88zF5ZnFwjTrZz-bOX2eqOEdLU/rs:fit:711:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5y/eHhSMWlaRWlyR2xu/TTFBbnNxMUVnSGFF/OCZwaWQ9QXBp ",

//     email: "JognJoggnDoeeee@gmail.com",
//     status: "active",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/sOyCmrSvw8ywoZqiR88zF5ZnFwjTrZz-bOX2eqOEdLU/rs:fit:711:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5y/eHhSMWlaRWlyR2xu/TTFBbnNxMUVnSGFF/OCZwaWQ9QXBp ",

//     email: "JognJoggnDoeeee@gmail.com",
//     status: "active",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/sOyCmrSvw8ywoZqiR88zF5ZnFwjTrZz-bOX2eqOEdLU/rs:fit:711:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5y/eHhSMWlaRWlyR2xu/TTFBbnNxMUVnSGFF/OCZwaWQ9QXBp ",

//     email: "JognJoggnDoeeee@gmail.com",
//     status: "active",
//   },
// ];

const ApprovedAdmins = ({ loading, setPageToShow, data }) => {
  const handleClick = () => {
    setPageToShow("");
  };
  return (
    <>
      <Button
        icon={Plus}
        letterCase="capitalize"
        type="button"
        iconPosition="left"
        onClick={handleClick}
      >
        Add Admin
      </Button>
      <div className="w-full h-5" />
      {/* <TvListTable tableHeader={tableHeader} tableBody={tableBody} /> */}
      <ApprovedAdminsTable
        tableBody={data}
        tableHeader={tableHeader}
        isLoading={loading}
      />
    </>
  );
};

export default ApprovedAdmins;
