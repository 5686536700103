import {
  ErrorX,
  Eyes,
  SuccessTick,
  EyesClosed,
} from "@app/components/Icon/icons";
import classNames from "classnames";
import React, { useState } from "react";
const Input = ({
  showIcon = false,
  labelTitle,
  loading,
  message,
  placeholder,
  variant = "transparent",
  name,
  value,
  disabled,
  elementToRight,
  className,
  textarea,
  rows,
  readonly,
  ...props
}) => {
  const [passwordShown, setPasswordShown] = useState(showIcon);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  return (
    <div className="text-left relative">
      {labelTitle && <label className="text-h2 leading-l7">{labelTitle}</label>}
      {textarea ? (
        <textarea
          disabled={disabled}
          name={name}
          value={value}
          placeholder={placeholder}
          className={classNames(
            " w-full py-[8px] px-4 rounded-[4px] border-[1px] outline-[0px] outline-secondary-06  focus:border-secondary-06 placeholder:text-h2 focus:caret-secondary-06  text-black-04",
            {
              "pr-10": showIcon,
              "bg-transparent border-neutral-02 placeholder:text-neutral-02":
                variant === "transparent",
              "cursor-not-allowed": loading,
              "text-neutral-07 disabled:bg-neutral-02": disabled,
            },
            className
          )}
          rows={rows || 10}
          {...props}
        />
      ) : (
        <>
          <div
            className={classNames("relative w-full", {
              "flex justify-between gap-5 ": elementToRight,
            })}
          >
            <input
              disabled={disabled}
              name={name}
              value={value}
              placeholder={placeholder}
              type={passwordShown ? "password" : "text"}
              className={classNames(
                " w-full py-[8px] px-4 rounded-[4px]  border-[1px] outline-[0px] outline-secondary-06 focus:border-secondary-06 placeholder:text-h2 focus:caret-secondary-06  text-black-04",
                {
                  "pr-10": showIcon,
                  "bg-transparent border-neutral-02 placeholder:text-neutral-02":
                    variant === "transparent",
                  "cursor-not-allowed": loading,
                  "text-neutral-07 disabled:bg-neutral-02": disabled,
                },
                className
              )}
              readOnly={readonly || loading}
              {...props}
            />
            {showIcon && (
              <span
                className={classNames(
                  "w-5 h-5 inline-flex cursor-pointer -translate-x-[calc(100%+10px)] left-full top-1/2  -translate-y-1/2 absolute items-center justify-center text-neutral-04 group-focus:text-[#060606] ",
                  {
                    "cursor-not-allowed": loading,
                  }
                )}
                onClick={togglePasswordVisiblity}
              >
                {!passwordShown ? <EyesClosed /> : <Eyes />}
              </span>
            )}
            <div className="flex-shrink-0">{elementToRight}</div>
          </div>

          {message && message.type !== "" && (
            <div className="flex items-center gap-2 mt-1">
              <p className="w-3 h-3">
                {message?.type === "error" && <ErrorX />}
                {message?.type === "success" && <SuccessTick />}
              </p>
              <p
                data-testid="alert"
                className={classNames("text-h1 ", {
                  "text-success": message?.type === "success",
                  "text-primary-04": message?.type === "error",
                })}
              >
                {message.value}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Input;
