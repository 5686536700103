import { SectionHeader } from "@app/components/section";
import React from "react";

const SettingsSection = () => {
  return (
    <section>
      <SectionHeader title="Account Settings" />

      <form></form>
    </section>
  );
};

export default SettingsSection;
