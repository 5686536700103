import { useFormik } from "formik";
import React, { useState } from "react";
import AuthForm from "../components/AuthForm";
import * as Yup from "yup";
import { storage } from "@app/utils";
import Input from "@app/components/common/Input";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Toast from "@app/components/Toast";
import { resendOTP, resetPassword } from "@app/api/authapi";
import Button from "@app/components/common/Button";

const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, "Password must be 8 characters or more.")
    .required("Password field can't be empty."),
  // token: Yup.string().email('Invalid email address').required('Required'),
});
const ResetPassword = () => {
  const [showResendOTP, setShowResendOTP] = useState(false);
  const [showProceedToLogin, setShowProceedToLogin] = useState(false);
  const email = storage.get("email");
  const id = storage.get("id");
  const code = storage.get("otp");

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleProceedToLogin = async () => navigate("/auth/login");

  const handleResendOTP = async () => {
    setIsLoading(true);
    if (!email) {
      toast.info(
        <Toast
          message="You will need to provide your email address again."
          toastType="info"
        />
      );

      setTimeout(() => navigate("/auth/forgot"), 1000);
      return;
    }
    resendOTP({ email })
      .then((res) => {
        if (res.statusCode === 200 && res.message) {
          toast.success(<Toast message={res.message} toastType="success" />);
          const { id } = res.data;
          storage.set("id", id);
          return;
        }
      })
      .then(() => {
        setTimeout(() => navigate("/auth/verify"), 500);
      })
      .catch((e) => {
        if (process.env.NODE_ENV === "developement") {
          console.log("error in ResetPassword.jsx", e);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const payload = {
        ...values,
        email,
        id,
        code,
      };

      if (!email || !id) {
        toast.info(
          <Toast
            toastType="info"
            message="Please kindly enter your email again"
          />
        );
        navigate("/auth/forgot");
      }
      if (!code) {
        toast.info(
          <Toast
            toastType="info"
            message="Please kindly enter your OTP again"
          />
        );
        navigate("/auth/verify");
      }
      setIsLoading(true);
      resetPassword(payload)
        .then((res) => {
          if (res.data && res.message && res.statusCode === 200) {
            toast.success(
              <Toast
                message="Your password has been reset successfully"
                toastType="success"
                // type="success"
              />
            );
          }
          setShowResendOTP(false);
          setShowProceedToLogin(true);
          return;
        })
        .then(() => {
          setTimeout(
            () => navigate("/auth/reset-successful", { replace: true }),
            1000
          );
          storage.clear("email");
          storage.clear("id");
          storage.clear("code");
        })
        .catch((e) => {
          console.log("error in ResetPassword.jsx: ", e);
          const error_message = "e?.response?.data?.message";
          if (error_message === "Code expired.") {
            setShowResendOTP(true);
          } else if (error_message === "Code has already been used.") {
            setShowResendOTP(true);
            setShowProceedToLogin(true);
          }
        })
        .finally(() => setIsLoading(false));
    },
  });

  if (!code || !id || !email) {
    return <Navigate to="/auth/forgot" replace />;
  }
  return (
    <AuthForm
      handleSubmit={formik.handleSubmit}
      isLoading={isLoading}
      text="Reset"
      showSocials={false}
      title={"Reset Password"}
      description={"You can now set a new password."}
      belowButtonText={
        <div>
          {showResendOTP && (
            <Button
              variant="link"
              className=" text-center w-full p-0 "
              letterCase=""
              onClick={handleResendOTP}
              type="button"
              // className="p-0"
            >
              Resend new OTP
            </Button>
          )}{" "}
          {showProceedToLogin && (
            <Button
              variant="link"
              className=" text-center w-full p-0 "
              letterCase=""
              onClick={handleProceedToLogin}
              type="button"
            >
              Proceed to Login
            </Button>
          )}
        </div>
      }
      // belowButtonText={
      //   <>
      //     {" "}
      //     Don't have an account ?{" "}
      //     <Link to="/auth/signin">
      //       <span className="text-primary-06 hover:underline hover:underline-offset-2">
      //         Sign in
      //       </span>
      //     </Link>
      //   </>
      // }
    >
      <Input
        onChange={formik.handleChange}
        value={formik.values.password}
        name="password"
        onBlur={formik.handleBlur}
        labelTitle="Password"
        loading={isLoading}
        showIcon
        // placeholder={"JohnDoe@gmail.com   "}
        //   showEyeIcon={item.name === "password"}
        variant="transparent"
        //   key={index}
        message={
          formik.touched.password &&
          formik.errors.password && {
            type: "error",
            value: formik.errors.password,
          }
        }
        //   helperText={item.helperText}
      />
    </AuthForm>
  );
};

export default ResetPassword;

// const OTP_VALUE = Object.values(formData).join("");
// // alert(OTP_VALUE);
// const email = storage.get("email");
// const id = storage.get("id");
// if (email && id) {
//   const data = {
//     email,
//     id,
//     code: OTP_VALUE,
//   };

//   alert(JSON.stringify(data, null, 2));

//     setIsLoading(true);
//    verifyUser(data).then(res => {

//    })
//     navigate("/auth/created");
//     console.log({ response });
//     alert("otp verified!")
//   } catch (e) {
//     console.log({ error: e });
//   }
//   setIsLoading(false)
// navigate("/auth/signup")
