import Button from "@app/components/common/Button";
import { SectionHeader } from "@app/components/section";
import Select from "@app/components/Select";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import { useErrorHandler } from "@app/utils/hooks";
import { useInfiniteMovies } from "@app/utils/hooks/reactQuery/movie";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import MoviesTable from "./MoviesTable";
import { Plus } from "@app/components/Icon/icons";
import classNames from "classnames";
const tableHeader = [
  {
    name: "No",
    // fration: "oneth",
    width: " w-[34px]",
  },
  { name: "Thumbnail", fraction: "threeth" },
  { name: "Name", fraction: "twoth" },
  { name: "Description", fraction: "fifth" },
  { name: "Type", fraction: "oneth" },
  { name: "Actions", fraction: "twoth" },
];

const MoviesSection = () => {
  const { logout } = useAuthContext();
  const [limit, setLimit] = useState({ name: "10", value: 10 });
  // const [lastMovieId] /= useState("");
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useErrorHandler(useInfiniteMovies, logout, "", limit.value);

  // console.log({data})
  //pp
  return (
    <>
      <div className="flex justify-between items-center w-full mb-6">
        <div className="space-x-3 flex ">
          <Link to="/movies/add">
            <Button icon={Plus} iconPosition="left">
              Add Movie
            </Button>
          </Link>
          <Link to="/movies/archive">
            <Button>Archive</Button>
          </Link>
        </div>
        <Select
          labeflText="Movies / page"
          className="w-[80px] relative"
          labelClassname="text-[10px]"
          selectedItem={limit}
          handleSelectedItem={setLimit}
          items={new Array(10).fill(0).map((item, index) => ({
            name: String((index + 1) * 10),
            value: (index + 1) * 10,
          }))}
        />
        {/* <div className="flex gap-2"> */}
        {/* <Button variant="transparent">Refresh</Button> */}
        {/* <Search handleInput={handleSearch} />
        </div> */}
      </div>

      <SectionHeader title="Movies list" total={data?.data?.length} />
      <section className="mt-6">
        <MoviesTable
          tableBody={data?.pages}
          tableHeader={tableHeader}
          infiniteScroll
          limit={limit.value}
          isLoading={isFetching && !isFetchingNextPage}
        />

        {
          <div
            className={classNames("flex justify-center my-4", {
              "my-20": isFetching && !isFetchingNextPage,
            })}
          >
            {!isLoading && (
              <Button
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage || isFetching}
                loading={isFetchingNextPage}
              >
                {hasNextPage ? "Load more movies" : "No more movies to load"}
              </Button>
            )}
          </div>
        }
        {/* <div>{isFetching && !isFetchingNextPage ? "Fetching..." : null}</div> */}
      </section>
    </>
  );
};

export default MoviesSection;
