import { CancelPerson, People, TickPerson } from "@app/components/Icon/icons";
import { APPROVED, DELETED, PENDING_APPROVAL, SUSPENDED } from "@app/constants";
import InnerDashboardLayout from "@app/layouts/InnerDashboardLayout";
import { storage } from "@app/utils";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import { useErrorHandler } from "@app/utils/hooks";
import { useAdmins } from "@app/utils/hooks/reactQuery/admin";
import React, { useMemo, useState } from "react";
import ManageAdminsSection from "./ManageAdminsSection";
import AddAdmin from "./ManageAdminsSection/AddAdmin";
import ApprovedAdmins from "./ManageAdminsSection/ApprovedAdmins";
import DeletedAdmins from "./ManageAdminsSection/DeletedAdmins";
import PendingAdmins from "./ManageAdminsSection/PendingAdmins";
import SuspendedAdmins from "./ManageAdminsSection/SuspendedAdmins";

const ManageAdmins = () => {
  const { logout } = useAuthContext();
  const { data, isFetching } = useErrorHandler(useAdmins, logout);

  const ADMINS = useMemo(() => {
    if (data && !isFetching) {
      const APPROVED_ADMINS = [];
      const PENDING_ADMINS = [];
      const SUSPENDED_ADMINS = [];
      const DELETED_ADMINS = [];
      data.forEach((admin) => {
        if (admin.status === PENDING_APPROVAL) {
          // if (admin.status !== APPROVED) {
          PENDING_ADMINS.push(admin);
        } else if (admin.status === APPROVED) APPROVED_ADMINS.push(admin);
        else if (admin.status === SUSPENDED) SUSPENDED_ADMINS.push(admin);
        else if (admin.status === DELETED) DELETED_ADMINS.push(admin);
      });

      return {
        PENDING_ADMINS,
        APPROVED_ADMINS,
        SUSPENDED_ADMINS,
        DELETED_ADMINS,
      };
    }
  }, [data, isFetching]);

  const MANAGE_ADMIN_COUNT = [
    {
      name: "Approved Admins",
      value: ADMINS?.APPROVED_ADMINS?.length,
      icon: TickPerson,
      id: "approved",
    },
    {
      name: "Pending Admins",
      value: ADMINS?.PENDING_ADMINS?.length,
      icon: People,
      id: "pending",
    },
    {
      name: "Suspended Admins",
      value: ADMINS?.SUSPENDED_ADMINS?.length,
      icon: CancelPerson,
      id: "suspended",
    },
    {
      name: "Deleted Admins",
      value: ADMINS?.DELETED_ADMINS?.length,
      icon: People,
      id: "deleted",
    },
  ];
  const [pageToShow, setPageToShow] = useState(() => {
    const adminPage = storage.get("admin_page_");
    const POSSIBLE_PAGES = {
      approved: true,
      pending: true,
      suspended: true,
      deleted: true,
    };
    // const type = storage.get("type");

    // const isValid = token && email && type;

    // if (adminPage === "approved" || adminPage === "pending" || ) {
    if (POSSIBLE_PAGES[adminPage]) return adminPage;

    return "";
  });

  const handlePageToShow = (page) => {
    setPageToShow(page);
    storage.set("admin_page_", page);
  };
  const child = () => {
    const PAGES_TO_SHOW = {
      approved: (
        <ApprovedAdmins
          setPageToShow={handlePageToShow}
          data={ADMINS?.APPROVED_ADMINS}
          loading={isFetching}
        />
      ),
      pending: (
        <PendingAdmins data={ADMINS?.PENDING_ADMINS} loading={isFetching} />
      ),
      suspended: (
        <SuspendedAdmins data={ADMINS?.SUSPENDED_ADMINS} loading={isFetching} />
      ),
      deleted: (
        <DeletedAdmins data={ADMINS?.DELETED_ADMINS} loading={isFetching} />
      ),
      "": <AddAdmin setPageToShow={handlePageToShow} />,
    };

    return PAGES_TO_SHOW[pageToShow];
    // if (pageToShow === "approved")
    //   return (
    //     <ApprovedAdmins
    //       setPageToShow={handlePageToShow}
    //       data={ADMINS?.APPROVED_ADMINS}
    //       loading={isFetching}
    //     />
    //   );

    // if (pageToShow === "pending")
    //   return (
    //     <PendingAdmins data={ADMINS?.PENDING_ADMINS} loading={isFetching} />
    //   );

    // if (pageToShow === "") return <AddAdmin setPageToShow={handlePageToShow} />;
    // pageToShow === "approved" ? <ApprovedAdmins setPageToShow={setPageToShow} /> : <PendingAdmins />;
  };
  return (
    <InnerDashboardLayout
      isFetching={isFetching}
      counts={MANAGE_ADMIN_COUNT}
      children={<ManageAdminsSection children={child()} />}
      clickableCards
      setPageToShow={handlePageToShow}
      pageToShow={pageToShow}
    />
  );
};

export default ManageAdmins;
