import InnerDashboardLayout from "@app/layouts/InnerDashboardLayout";
import React from "react";
import AddItemSection from "./AddItemSection";

const AddItem = () => {
  return (
    <InnerDashboardLayout
      // counts={DASHBOARD_COUNTS}
      children={<AddItemSection />}
    />
  );
};

export default AddItem;
