// import { Spinner } from "@app/components/Icon/icons";
import classNames from "classnames";
import React from "react";
// import Spinner from "@app/components/Spinner";
import Spinner from "@app/components/Spinner";

// variant = "standard" | "transparent" | "link"
// size = "small" | "medium" | "large"
const Button = ({
  variant = "standard",
  disabled = false,
  iconPosition = "",
  icon: Icon,
  children,
  loading = false,
  spinner: SpinnerComponent = Spinner,
  size = "medium",
  letterCase = "capitalize",
  className,
  type = "button",
  ...props
}) => {
  return (
    <button
      className={classNames(
        "flex items-center justify-center rounded-[4px] focus:border-none focus-visible:outline-black-03",
        {
          " cursor-not-allowed": loading,
          "font-normal py-[8px] px-3": size === "small",
          "py-[10px] px-4": size === "medium",
          " py-[12px] px-4": size === "large",
          "font-semibold": size !== "small",
          "flex items-center gap-2": Icon,
          "border-[1px] border-primary-05":
            variant === "standard" || variant === "transparent",
          "border-none text-primary-06 hover:underline hover:underline-offset-2":
            variant === "link",
          "bg-primary-05 text-[#ffffff] hover:bg-primary-04 active:bg-primary-06":
            variant === "standard" && !disabled,
          " text-primary-05 hover:bg-primary-01 hover:text-primary-04  active:border-primary-06 active:bg-primary-02":
            variant === "transparent" && !disabled,

          "bg-black-03 border-black-03 text-[#ffffff]":
            variant === "standard" && disabled,
          "border-black-03 text-black-03":
            variant === "transparent" && disabled,
          "border-[1px] border-[#808080] text-[#808080] active:border-black-03 active:border-[1px] hover:text-black-03 hover:border-black-03 bg-transparent":
            variant === "checkbox",
          "border-[1px] border-black-03 text-[#fff]  bg-black-03 active:border-black-03 active:border-[1px]":
            variant === "checkbox-selected",
        },
        className
      )}
      type={type}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? (
               <Spinner show={loading} />
      ) : (
        <>
          {iconPosition === "left" && (
            <span className="text-inherit w-[18px] h-[18px]  flex items-center justify-center ">
              {Icon && <Icon />}
            </span>
          )}
          <span
            className={classNames("font-roboto font-medium", {
              " text-h1 ": size === "small",
              "text-h2": size !== "small",
              uppercase: letterCase === "uppercase",
              lowercase: letterCase === "lowercase",
              capitalize: letterCase === "capitalize",
            })}
          >
            {children}
          </span>
          {iconPosition === "right" && (
            // <span className="text-inherit w-[15px] h-[10px]  ">
            <span className="text-inherit w-[18px] h-[18px] flex items-center justify-center ">
              {Icon && <Icon />}
            </span>
          )}
        </>
      )}
    </button>
  );
};

export default Button;
