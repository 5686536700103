import React from "react";
import Table from "@app/components/common/Table";
import classNames from "classnames";
import { Delete, VanillaTickMark } from "@app/components/Icon/icons";
import ActionsButtonList from "@app/components/common/ActionsButtonList";
import StatusCard from "@app/components/common/StatusCard";
// import TableBody from "@app/components/common/Table/TableBody";

const SuspendedAdminsTable = ({ tableHeader, tableBody, isLoading }) => {
  const ACTIONS = [
    {
      icon: VanillaTickMark,
      action: "approve",
      // onClick: (index) => navigate(`./edit/${index}`),
    },
    // {
    //   icon: Pencil,
    //   action: "suspend",
    //   // onClick: (index) => navigate(`./edit/${index}`),
    // },
    { icon: Delete, action: "delete" },
  ];

  return (
    <Table
      emptyTableText={
        !!tableBody?.length ? null : "There are no suspended admins currently."
      }
      isLoading={isLoading}
    >
      <Table.Header data={tableHeader} />
      <Table.Body>
        {tableBody?.map((item, index) => (
          <tr className={classNames("h-16 ", { "bg-black-01": !(index % 2) })}>
            <td className="text-center">{index + 1}</td>
            {/* <td className="h-24 flex justify-start ">
              <div className="h-full aspect-[142/64] relative overflow-hidden align-middle">
                <img
                  src={item?.image}
                  alt={item?.title}

                  className="absolute top-0 left-0 right-0 bottom-0 "
                />
              </div>
            </td> */}
            <td className="  ">{item?.email}</td>
            <td className="">{<StatusCard status="suspended" />}</td>
            <td>{<ActionsButtonList items={ACTIONS} id={item.adminId} />}</td>
          </tr>
        ))}
      </Table.Body>
    </Table>
  );
};

export default SuspendedAdminsTable;
