import React, { useEffect, useMemo } from "react";
import Table from "@app/components/common/Table";
import classNames from "classnames";
import { Delete } from "@app/components/Icon/icons";
import ActionsButtonList from "@app/components/common/ActionsButtonList";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import { useErrorHandler } from "@app/utils/hooks";
import { useEditSubscription } from "@app/utils/hooks/reactQuery/subscription";
import { toast } from "react-toastify";
import Toast from "@app/components/Toast";
const SubscriptionsTable = ({ tableHeader, tableBody, isLoading }) => {
  const { logout } = useAuthContext();
  const { mutate, data, error, isError } = useErrorHandler(
    useEditSubscription,
    logout
  );
  const ACTION_ITEMS = useMemo(
    () => [
      {
        icon: Delete,
        action: "delete",
        onClick: (index) => {
          mutate(index);
        },
      },
    ],
    [mutate]
  );

  useEffect(() => {
    // console.log({ data, error, isError });
    if (data?.statusCode === 200 && !error && !isError) {
      toast.success(
        <Toast
          message="Subscription was deleted successfully!"
          toastType="success"
        />
      );
    }
  }, [data, error, isError]);
  return (
    <Table
      emptyTableText={
        !!tableBody?.length && !isLoading
          ? null
          : "There are no subscriptions yet!"
      }
      isLoading={isLoading}
    >
      <Table.Header data={tableHeader} />
      <Table.Body>
        {tableBody?.map((item, index) => (
          <tr
            className={classNames("h-14 ", { "bg-black-01": !(index % 2) })}
            key={item.id}
          >
            <td className="text-center">{index + 1}</td>
            <td className=" py-3">{item?.title}</td>
            <td className="  ">{item?.description}</td>
            <td>{item?.price}</td>
            <td>{item?.duration}</td>
            <td>
              <ActionsButtonList items={ACTION_ITEMS} id={item.id} />
            </td>
          </tr>
        ))}
      </Table.Body>
    </Table>
  );
};

export default SubscriptionsTable;
