import Form from "@app/components/common/Form";
import Input from "@app/components/common/Input";
// import { REGEX_EMAIL } from "@app/constants";
import { useErrorHandler } from "@app/utils/hooks";
import { useAddNotification } from "@app/utils/hooks/reactQuery/notification";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import Toast from "@app/components/Toast";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const initialValues = {
  title: "",
  message: "",
};

const VALIDATION_SCHEMA = Yup.object({
  title: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
});

const AddPushNotificationsSection = () => {
  const { mutate, data, isLoading, error } =
    useErrorHandler(useAddNotification);

  const formik = useFormik({
    initialValues,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) => {
      
      mutate(values);
    },
  });
  const navigate = useNavigate();
  useEffect(() => {
    // console.log({ data });
    // if (data && !isLoading) {
    //   toast.success(
    //     <Toast message="Notification sent successfully!" toastType="success" />
    //   );
      
    //   // formik.resetForm()
    //   // navigate("/push-notification");
    // }
  }, [data, formik, isLoading, navigate]);
  const INFO = [
    {
      info_type: "Title",
      placeholder: "placeholder",
      value: formik.values.title,
      name: "title",

      message: formik.touched.title &&
        formik.errors.title && {
          type: "error",
          value: formik.errors.title,
        },
    },
    {
      info_type: "Message",
      placeholder: "placeholder",
      value: formik.values.message,
      name: "message",

      message: formik.touched.message &&
        formik.errors.message && {
          type: "error",
          value: formik.errors.message,
        },
    },
  ];
  return (
    <Form
      handleSubmit={formik.handleSubmit}
      isLoading={isLoading}
      buttonText="Send Notification"

      // handleToggle={handleToggle}
    >
      {INFO.map((item) => (
        <Input
          labelTitle={item.info_type}
          placeholder={item.placeholder}
          onBlur={formik.handleBlur}
          loading={isLoading}
          variant="transparent"
          value={item.value}
          message={item.message}
          textarea={item.name === "message"}
          name={item.name}
          onChange={formik.handleChange}
        />
      ))}
    </Form>
  );
};

export default AddPushNotificationsSection;
