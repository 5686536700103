import Toast from "@app/components/Toast";
import { useErrorHandler } from "@app/utils/hooks";
import { useEditGenre } from "@app/utils/hooks/reactQuery/genre";
import classNames from "classnames";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
const WORDS = {
  active: "Active",
  pending: "Pending",
  inactive: "Inactive",
  deleted: "Deleted",
  suspended: "Suspended",
};
const StatusCard = ({ status, text, genre }) => {
  const { isLoading, mutate, data } = useErrorHandler(
    useEditGenre,
    "",
    genre?.genreSlug
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    mutate({ ...genre, active: !genre.active });
    await data;
  };

  useEffect(() => {
    if (data && !isLoading) {
      toast.success(
        <Toast
          message={"Status was successfully updated."}
          toastType="success"
        />
      );
    }
  }, [data, isLoading]);

  return (
    <div
      className={classNames(
        "min-w-[70px] py-[2px] px-3 rounded-[16px]  inline-flex gap-1 items-center justify-center text-neutral-07",
        {
          "bg-success text-neutral-07": status,
          "bg-primary-05 text-neutral-07": status === "deleted",
          "bg-pending text-neutral-07":
            status === "pending" ||
            status === "suspended" ||
            status === "inactive",
          "cursor-pointer": genre && !isLoading,
          "cursor-not-allowed": isLoading,
        }
      )}
      onClick={genre && !isLoading ? handleSubmit : () => {}}
    >
      <span className="bg-[#272C28] w-1 h-1 rounded-full"></span>
      <span>{text ? text : WORDS[status]}</span>
    </div>
  );
};

export default StatusCard;
