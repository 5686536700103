import Button from "@app/components/common/Button";
import { Plus } from "@app/components/Icon/icons";
import { SectionHeader } from "@app/components/section";
import Spinner from "@app/components/Spinner";
import TableBodyPlaceholder from "@app/components/TableBodyPlaceholder";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import { useErrorHandler } from "@app/utils/hooks";
import { useInfiniteLandingItems } from "@app/utils/hooks/reactQuery/landingItems";
// import { useInfiniteMovies } from "@app/utils/hooks/reactQuery/movie";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ManageHomePageTable from "./components/ManageHomePageTable";
// import ManageHomePageSection from '.'

const tableHeader = [
  {
    name: "No",
    // fration: "oneth",
    width: "w-[34px]",
  },
  { name: "Image", fraction: "twoth" },
  { name: "Name", fraction: "twoth" },
  { name: "Description", fraction: "fourth" },
  // { name: "Edit ", fraction: "twoth" },
  { name: "Remove Item", fraction: "oneth", className: "text-primary-06" },
];

const tableBody = [
  {
    id: "oQBqi1shMlPwswRNn8LG",
    movieId: "aXcss",
    type: "advertisement",
    title: "The coming of a big bro",
    imageUrl: "https://www.yux",
    // "createdAt": {
    // "_seconds": 1672659643,
    // "_nanoseconds": 650000000
    // },
    description: "This is a test description of what the homepage looks like.",
  },
];

const ManageHomePageSection = () => {
  const { logout } = useAuthContext();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useErrorHandler(useInfiniteLandingItems, logout, "", 10);
  // console.log({ data });
  const [items, setItems] = useState([]);
  // console.log({ items });
  useEffect(() => {
    if (data && !isFetching && !isLoading) {
      setItems([]);
      data.pages.forEach((item) =>
        setItems((items) => [...items, ...item.data])
      );
    }
  }, [data, isFetching, isLoading]);
  const navigate = useNavigate();
  return (
    <>
      <div className="flex justify-between items-center w-full mb-6">
        {/* <Link to="add"> */}
        <Button
          icon={Plus}
          iconPosition="left"
          letterCase="capitalize"
          // disabled={isLoading}
          onClick={() => {
            // if (!isLoading) {
            navigate("add");
            // }
          }}
        >
          Add Home Page Item
        </Button>
        {/* </Link> */}
      </div>

      <SectionHeader title="Home Page Items" />
      {/* 
      <div className=" mt-5 mb-8">
        <Search handleInput={() => {}} />
      </div> */}

      <ManageHomePageTable
        tableHeader={tableHeader}
        tableBody={items}
        // isLoading={isLoading}
        placeholder={TableBodyPlaceholder}
        isLoading={isFetching && !isFetchingNextPage}
        spinner={<Spinner color="dark" show />}
      />

      {
        <div
          className={classNames("flex justify-center my-4", {
            "my-20": isFetching && !isFetchingNextPage,
          })}
        >
          {!isLoading && !!items.length && (
            <Button
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage || isFetching}
              loading={isFetchingNextPage}
            >
              {hasNextPage ? "Load more items" : "No more items to load"}
            </Button>
          )}
        </div>
      }
    </>
  );
};

export default ManageHomePageSection;
