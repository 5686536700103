import React from "react";
import { forwardRef } from "react";

const InputBoxItem = forwardRef(({ name, value, handleChange }, ref) => {
  return (
    <div
      className="p-2 border-[1px] rounded-[8px] border-primary-06 w-[40px] h-[50px] flex justify-center items-center text-h4 font-semibold"
      onClick={(e) => e.currentTarget.querySelector("input").focus()}
    >
        {
         
            <input
              type={"tel"}
              pattern={/^[0-9]$/.source}
            //   pattern="[0-9]{1}"
            //   pattern="[0-9]{1}"
              name={name}
              value={value}
              className=" w-[24px] p-1 inline-block focus:outline-none "
              maxLength={1}
              ref={ref}
              onChange={handleChange}
         size="1"
            />
        }
    </div>
  );
});

export default InputBoxItem;
