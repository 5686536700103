import classNames from "classnames";
import { useSelect } from "downshift";
import React from "react";
import Skeleton from "../common/Skeleton";
// import { CaretLeft } from "../Icon/icons";
import { CaretDown } from "../Icon/icons";
// const MOVIE_TYPES = [
//   { name: "Full Movie", value: "fullMovie" },
//   { name: "Series", value: "series" },
// ];
// function itemToString(item) {
//   return item ? item.title : "";
// }

const Select = ({
  selectedItem,
  handleSelectedItem,
  items,
  className,
  labeflText,
  labelClassname,
  isLoading,
  itemsExist= true,
}) => {
    const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    selectedItem: selectedItem || items[0] || {},
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) =>
      handleSelectedItem(newSelectedItem),
  });

  return isLoading ? (
    <Skeleton height="50px" width="100%" />
  ) : (
    itemsExist && (
      <div className={classNames("relative", className)}>
        <div className="flex flex-col ">
          <label
            {...getLabelProps()}
            className={classNames(
              "text-h2 leading-l7 mb-[2px]",
              labelClassname
            )}
          >
            {labeflText}
          </label>
          <div
            className="p-2 px-4 bg-[#fff]  flex justify-between items-center cursor-pointer border-[1px] border-neutral-02 rounded-[4px] outline-secondary-06  focus:border-secondary-06"
            {...getToggleButtonProps()}
          >
            <span>{selectedItem?.name}</span>
            {isOpen ? (
              <span className="px-2 -rotate-180">
                <CaretDown />
              </span>
            ) : (
              <span className="px-2 ">
                <CaretDown />
              </span>
            )}
          </div>
        </div>
        <ul
          {...getMenuProps()}
          className="absolute w-full p-0 bg-[#FFF] z-40 shadow-md  max-h-80 overflow-y-auto overflow-x-none"
        >
          {isOpen &&
            items.map((item, index) => (
              <li
                className={classNames(
                  highlightedIndex === index && "bg-secondary-05",
                  selectedItem === item && "font-medium",
                  "py-2 px-3 shadow-sm flex flex-col"
                )}
                key={`${item.value}${index}`}
                {...getItemProps({ item, index })}
              >
                <span>{item.name}</span>
              </li>
            ))}
        </ul>
      </div>
    )
  );
};

export default Select;
