import {
  addEpisodeToMovie,
  addMovie,
  editEpisode,
  editMovie,
  getAllEpisodes,
  getAllMovies,
  getArchivedMovies,
} from "@app/api/movieapi";

const {
  useQuery,
  useQueryClient,
  useMutation,
  useInfiniteQuery,
} = require("react-query");

const useMovies = (id, limit, lastMovieId) => {
  const { isFetching, data, isError, error } = useQuery({
    queryKey: ["movies", limit],
    queryFn: () => getAllMovies(limit, lastMovieId),
    retry: false,
  });
  return { isFetching, data, isError, error };
};

// const fetchProjects = ()

const useEpisodes = (id) => {
  const { data, isError, error, isLoading } = useQuery({
    queryKey: ["episodes", id],
    queryFn: () => getAllEpisodes(id),
    retry: false,
  });
  return { isLoading, data, isError, error };
};

const useInfiniteMovies = (id, limit) => {
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    isLoading,
    isError,
  } = useInfiniteQuery(
    "movies",
    ({ pageParam }) => {
      // console.log({ pageParam });
      return getAllMovies({ lastMovieId: pageParam, limit });
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastMovie, allMovies) => {
        // console.log({ allMovies });
        const data = allMovies[allMovies.length - 1]?.data;
        const last = data?.[data.length - 1];

        // console.log({ data, last });
        return last?.id;
      },
    }
  );
  return {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    isLoading,
    isError,
  };
};

const useEditMovie = (IsDelete) => {
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, isLoading } = useMutation({
    mutationFn: editMovie,
    onSuccess: () => {
      // Invalidate and refetch
      if (!IsDelete) {
        queryClient.invalidateQueries({ queryKey: "movies" });
      }
    },
  });
  return { mutate, isLoading, data, isError, isSuccess };
};

const useEditEpisode = (id) => {
  const queryClient = useQueryClient();
  const { mutate, data, isLoading, isError } = useMutation({
    mutationFn: editEpisode,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["episodes", id] });
    },
  });
  return { mutate, isLoading, data, isError };
};

const useAddMovie = () => {
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, isLoading } = useMutation({
    mutationFn: addMovie,

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: "movies" });
    },
  });
  return { mutate, isLoading, data, isError, isSuccess };
};

const useAddEpisodeToMovie = () => {
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, isLoading } = useMutation({
    mutationFn: addEpisodeToMovie,
    // mutationKey: ['genres' , slug],
    // onMutate: async (newGenre) => {
    //   // Cancel any outgoing refetches
    //   // (so they don't overwrite our optimistic update)
    //   await queryClient.cancelQueries({ queryKey: "genres" });

    //   // Snapshot the previous value
    //   const previousGenres = await queryClient.getQueryData("genres");
    //   console.log({ previousGenres });
    //   // Optimistically update to the new value
    //   queryClient.setQueryData("genres", (old) => [...old, newGenre]);

    //   // Return a context object with the snapshotted value
    //   return { previousGenres };
    // },
    // // If the mutation fails,
    // // use the context returned from onMutate to roll back
    // onError: (err, newGenre, context) => {
    //   queryClient.setQueryData("genres", context.previousGenres);
    // },
    // // Always refetch after error or success:
    // onSettled: () => {
    //   queryClient.invalidateQueries({ queryKey:"genres" });
    // },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: "movies" });
    },
  });
  return { mutate, isLoading, data, isError, isSuccess };
};

const useGetArchivedMovie = () => {
  const { data, isError, error, isLoading } = useQuery({
    queryKey: "archived-movies",
    queryFn: () => getArchivedMovies(),
    retry: false,
  });
  return { isLoading, data, isError, error };
};

export {
  useMovies,
  useAddMovie,
  useAddEpisodeToMovie,
  useEditMovie,
  useInfiniteMovies,
  useEpisodes,
  useEditEpisode,
  useGetArchivedMovie,
  // , useEditGenre,
  //  useAddGenre
};
