import classNames from "classnames";
import React from "react";
import { Pause, Play, Stop } from "../Icon/icons";
import UploadControllerButton from "./UploadControllerButton";

const UPLOAD_OPTIONS = [
  //   {
  //     identity: "paused",
  //     Icon: Pause,
  //     action: async (obj) => await obj.pause(),
  //   },
  {
    identity: "running",
    Icon: Play,
    action: (obj) => obj.resume(),
  },
  {
    identity: "stopped",
    Icon: Stop,
    action: (obj) => obj.cancel(),
  },
];

const UploadController = ({

  uploadTitle,
  progress,
  state,
  uploadObject,
  size,
  showButtons  = true
}) => {
  //   console.log({ uploadObject });
  return (
    <>
      <div>{uploadTitle}</div>
      <div
        className={classNames(
          "bg-neutral-01 rounded-[20px] overflow-hidden w-96 h-2",
          {
            "h-1 w-48": size === "small",
          }
        )}
      >
        <div
          className="bg-secondary-06 animate-pulse h-full"
          style={{ width: `${progress}%` }}
        ></div>
      </div>

      {/* <div className="flex gap-3">
        {showButtons && UPLOAD_OPTIONS.map((option, index) => (
          <UploadControllerButton
            Icon={option.Icon}
            identity={option.identity}
            state={state}
            key={index}
            handleClick={() => option.action(uploadObject)}
          />
        ))}
      </div> */}
    </>
  );
};

export default UploadController;
