import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import classNames from "classnames";
import { VanillaX } from "./components/Icon/icons";
import AuthProvider from "./utils/contexts.js/AuthProvider";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  AddEpisode,
  AddGenre,
  AddHomePageItem,
  AddMovie,
  AddPushNotifications,
  AddSubscriptions,
  AddTvProgrammes,
  EditEpisode,
  EditGenre,
  EditPushNotifications,
  EditTvProgrammes,
  Episodes,
  Forgot,
  Genres,
  Logout,
  ManageAdmins,
  ManageHomePage,
  Movies,
  NotApproved,
  PushNotifications,
  ResetSuccessful,
  Settings,
  Signin,
  Signup,
  Subscriptions,
  Verify,
  ArchivedMovies
} from "./views";
import PrivateRoute from "./components/PrivateRoute";
import { DashboardLayout } from "./layouts";
import ResetPassword from "./views/Authentication/ResetPassword/ResetPassword";
import EditMovie from "./views/Movies/EditMovie/EditMovie";
import GlobalStateProvider from "./utils/contexts.js/GlobalStateProvider";
import TvProgrammes from "./views/TvProgrammes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const contextClass = {
  success: "border-[#1CCB57] bg-[#D2F5DD]",
  error: "border-primary-05 bg-[#FFD8D8]",
  warning: "border-[#FAC91C] bg-[#FEF4D2]",
  info: "border-[#65B7E9] bg-[#E0F1FB]", 
};

function App() {
  return (
    <QueryClientProvider client={queryClient} >
      {/* <AuthProvider> */}
      {/* {user ? ( */}
      {/* <Routes> */}
      <GlobalStateProvider>
        <AuthProvider>
           <Routes>
            <Route
              path="/"
              element={<PrivateRoute component={DashboardLayout} />}
            >
              <Route index element={<PrivateRoute component={Movies} />} />
              {/* <Route
                path="/dashboard"
                element={<PrivateRoute component={Dashboard} />}
              /> */}
              <Route
                path="movies"
                index
                element={<PrivateRoute component={Movies} />}
              />
              <Route
                path="movies/add"
                element={<PrivateRoute component={AddMovie} />}
              />
              <Route
                path="movies/archive"
                element={<PrivateRoute component={ArchivedMovies} />}
              />
              <Route
                path="movies/edit/:id"  
                element={<PrivateRoute component={EditMovie} />}
              />
              <Route
                path="movies/:movieId/episodes"
                element={<PrivateRoute component={Episodes} />}
              />
                <Route
                  path="movies/add-episode/:seriesId"
                  element={<PrivateRoute component={AddEpisode} />}
                />
              <Route
                path="movies/:seriesId/episodes/edit/:episodeId"
                element={<PrivateRoute component={EditEpisode} />}
              />
              <Route
                path="manage-genres"
                element={<PrivateRoute component={Genres} />}
              />
              <Route
                path="manage-genres/edit"
                element={<PrivateRoute component={EditGenre} />}
              />
              <Route
                path="manage-genres/add"
                element={<PrivateRoute component={AddGenre} />}
              />

              <Route
                path="epg"
                element={<PrivateRoute component={TvProgrammes} />}
              />
              <Route
                path="tv-programmes/edit/:id"
                element={<PrivateRoute component={EditTvProgrammes} />}
              />
              <Route
                path="tv-programmes/add"
                element={<PrivateRoute component={AddTvProgrammes} />}
              />
              <Route
                path="settings"
                element={<PrivateRoute component={Settings} />}
              />
              {/* <Route
                path="push-notifications"
                element={<PrivateRoute component={PushNotifications} />}
              />
              <Route
                path="push-notifications/add"
                element={<AddPushNotifications />}
              />
              <Route
                path="push-notifications/edit/:id"
                element={<EditPushNotifications />}
              /> */}
              <Route
                path="subscriptions"
                element={<PrivateRoute component={Subscriptions} />}
              />
              <Route
                path="subscriptions/add"
                element={<PrivateRoute component={AddSubscriptions} />}
              />
              {
                <Route
                  path="manage-admins"
                  element={
                    <PrivateRoute
                      component={ManageAdmins}
                      exclusivelyForSuperAdmin
                    />
                  }
                />
              }
              <Route
                path="manage-home-page"
                element={
                  <PrivateRoute
                    component={ManageHomePage}

                  />
                }
              />
              <Route
                path="manage-home-page/add"
                element={
                  <PrivateRoute
                    component={AddHomePageItem}

                  />
                }
              />
              <Route
                path="/logout"
                element={<PrivateRoute component={Logout} />}
              />
            </Route>

            <Route
              path="/auth"
              element={<Navigate to="/auth/signup" replace />}
            />
            <Route path="/auth/signup" element={<Signup />} />
            <Route path="/auth/signin" element={<Signin />} />
            <Route
              path="/auth/reset-successful"
              element={<ResetSuccessful />}
            />
            {<Route path="/auth/verify" element={<Verify />} />}
            <Route path="/auth/forgot" element={<Forgot />} />
            <Route path="/auth/not-approved" element={<NotApproved />} />

            <Route path="/auth/reset" element={<ResetPassword />} />

            <Route path="*" element={<Navigate to="auth/signup" replace />} />
          </Routes>
        </AuthProvider>
      </GlobalStateProvider>

      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        icon={false}
        toastClassName={({ type }) =>
          classNames(
            contextClass[type || "default"],
            " rounded-[4px] border-[1px] h-max relative flex "
          )
        }
        closeButton={({ type, closeToast }) => (
          <span
            onClick={closeToast}
            className={classNames("h-2 w-2 flex-shrink-0 p-2 mr-3", {
              "text-primary-05": type === "error",
              "text-[#1CCB57]": type === "success",
              "text-[#FAC91C]": type === "warning",
              "text-[#65B7E9]": type === "info",
            })}
          >
            <VanillaX className="w-3 h-3" />
          </span>
        )}
      />
    </QueryClientProvider>
  );
}

export default App;
