import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import AuthForm from "../components/AuthForm";
import * as Yup from "yup";
import { REGEX_EMAIL } from "@app/constants";
import { Link, useNavigate } from "react-router-dom";
import Input from "@app/components/common/Input";
import { storage } from "@app/utils";
import { forgotPassword } from "@app/api/authapi";
import { toast } from "react-toastify";
import Toast from "@app/components/Toast";

const Forgot = () => {
  
 
  useEffect(() => {
    //on visiting Forgot password , clear any local storage of email
    storage.clear("email");
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .matches(REGEX_EMAIL, "Email address is invalid!")
        .required("Please this field cannot be empty!"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      // storage.set("email", values.email);
      // const response = await forgotPassword(values);
      forgotPassword(values)
        .then((res) => {
          // alert("there was a successful response");
          if (res?.message === "Network Error") throw res;
          if (res.message === "success" && res.statusCode === 200) {
            toast.success(
              <Toast
                toastType="success"
                message="An OTP has been sent to your email address."
              />
            );
            storage.set("id" , res.data.id)
            storage.set("email", values.email);
          }
        })
        .then(() => {
          setTimeout(() => {
            // alert(JSON.stringify(values, null, 2));
            // setIsLoading(false);
            navigate("/auth/verify");
          }, 1000);
        })
        .catch((e) => console.log("error in Forgot.jsx: ", e))
        .finally(() => setIsLoading(false));

      // setIsLoading(false);
    },
  });
  // const handleSubmit = () => {};

  return (
    <AuthForm
      handleSubmit={formik.handleSubmit}
      isLoading={isLoading}
      text="Continue"
      showSocials={false}
      title={"Trouble loggin in?"}
      description={
        "Enter the email associated with your account and we will send you a reset link."
      }
      belowButtonText={
        <>
          {" "}
          Don't have an account ?{" "}
          <Link to="/auth/signin">
            <span className="text-primary-06 hover:underline hover:underline-offset-2">
              Sign in
            </span>
          </Link>
        </>
      }
    >
      <Input
        onChange={formik.handleChange}
        value={formik.values.email}
        name="email"
        onBlur={formik.handleBlur}
        labelTitle="Email"
        loading={isLoading}
        placeholder={"JohnDoe@gmail.com   "}
        //   showEyeIcon={item.name === "password"}
        variant="transparent"
        //   key={index}
        message={
          formik.touched.email &&
          formik.errors.email && {
            type: "error",
            value: formik.errors.email,
          }
        }
        //   helperText={item.helperText}
      />
    </AuthForm>
  );
};

export default Forgot;
