import { SectionHeader } from "@app/components/section";
import React from "react";

import FullMovieForm from "./FullMovieForm";

const MovieInformation = () => {
  return (
    <div>
      <SectionHeader title="Movie Information" />

      <FullMovieForm />
      
    </div>
  );
};

export default MovieInformation;
