import SvgXWhite from '@app/components/Icon/icons/XWhite';
import React from 'react'

const Thumbnail = ({ files, handleCancelThumbnail }) => {
    const file = files[0];
    return (
      <div className="w-32 aspect-video relative my-1">
        <img src={file?.preview} alt="" />
        <span
          className="text-black-02 w-8 bg-[#ffffff] rounded-full cursor-pointer h-8 absolute top-0 right-0 -translate-y-1/2 translate-x-1/2 hover:scale-90"
          onClick={handleCancelThumbnail}
        >
          <SvgXWhite />
        </span>
      </div>
    );
  };
  

export default Thumbnail