import * as React from "react";
const Svg404 = (props) => (
  <svg
    width={459}
    height={401}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.2}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M428.044 200.143c0 110.535-89.607 200.142-200.142 200.142-110.536 0-200.143-89.607-200.143-200.142C27.76 89.607 117.366 0 227.902 0c110.535 0 200.142 89.607 200.142 200.143 0 110.535 0 0 0 0Z"
      fill="url(#404_svg__a)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.829 212.983h-6.644v10.236c0 4.579-1.257 7.991-3.771 10.416-2.514 2.424-5.837 3.591-9.877 3.591-4.041 0-7.363-1.167-9.877-3.591-2.514-2.425-3.771-5.837-3.771-10.416v-10.236H61.43c-3.681 0-6.644-1.078-8.8-3.322-2.154-2.245-3.232-5.029-3.232-8.351 0-3.322 1.167-6.734 3.412-10.056l53.425-76.322c1.437-2.065 3.233-3.592 5.388-4.579 2.155-.988 4.4-1.527 6.824-1.527 3.681 0 6.914 1.257 9.697 3.682 2.784 2.424 4.131 5.836 4.131 10.146v68.959h6.644c8.62.09 13.02 3.591 13.02 10.595 0 7.183-4.4 10.775-13.11 10.775Zm-33.851-60.698-27.386 39.418h27.386v-39.418ZM382.969 212.983h-6.644v10.236c0 4.579-1.257 7.991-3.772 10.416-2.514 2.424-5.836 3.591-9.877 3.591-4.04 0-7.362-1.167-9.876-3.591-2.515-2.425-3.772-5.837-3.772-10.416v-10.236H305.48c-3.681 0-6.644-1.078-8.799-3.322-2.155-2.245-3.233-5.029-3.233-8.351 0-3.322 1.167-6.734 3.412-10.056l53.425-76.322c1.437-2.065 3.233-3.592 5.388-4.579 2.155-.988 4.4-1.527 6.824-1.527 3.681 0 6.914 1.257 9.697 3.682 2.784 2.424 4.131 5.836 4.131 10.146v68.959h6.644c8.71.09 13.109 3.591 13.109 10.595 0 7.183-4.399 10.775-13.109 10.775Zm-33.851-60.698-27.386 39.418h27.386v-39.418Z"
      fill="#FF3A3A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M277.285 169.076c0 30.647-24.843 55.491-55.49 55.491-30.647 0-55.49-24.844-55.49-55.491 0-30.646 24.843-55.49 55.49-55.49 30.647 0 55.49 24.844 55.49 55.49Z"
      fill="#E90101"
    />
    <path
      d="M191.537 199.24c-2.694-2.694-2.694-7.093 0-9.787l50.642-50.642c2.693-2.693 7.093-2.693 9.787 0 2.693 2.694 2.693 7.094 0 9.787l-50.552 50.732c-1.347 1.347-3.143 1.975-4.939 1.975-1.795 0-3.501-.718-4.938-2.065Z"
      fill="#FAC91C"
    />
    <path
      d="M67.985 248.447c1.886-15.444 11.493-22.986 11.942-23.345l2.874 3.771-1.437-1.886 1.437 1.886c-.09.09-8.53 6.824-10.147 20.203-1.706 14.546 4.849 31.247 19.575 49.654l-3.682 3.052C73.013 282.298 66.1 264.34 67.985 248.447Z"
      fill="#BEC9DB"
    />
    <path
      d="m83.16 229.865 3.052-1.885a3.121 3.121 0 0 0 .808-4.49l-.359-.449c-1.077-1.346-3.142-1.526-4.4-.359l-2.693 2.425c-1.437 1.077-1.437 3.322 0 4.489.987.898 2.514.988 3.591.269Z"
      fill="#65B7E9"
    />
    <path
      d="M121.679 303.582c19.754-12.84 31.247-26.668 34.21-41.124 2.694-13.289-3.143-22.178-3.232-22.268l3.95-2.694c.27.449 7.094 10.685 3.951 25.86-3.232 15.803-15.444 30.618-36.275 44.266l-2.604-4.04Z"
      fill="#BEC9DB"
    />
    <path
      d="m152.118 240.999-2.335-2.784c-1.167-1.437-.808-3.502.719-4.579l.538-.359c1.437-.988 3.502-.449 4.31 1.077l1.796 3.143c.898 1.616.18 3.681-1.526 4.4-1.257.538-2.694.179-3.502-.898Z"
      fill="#65B7E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M257.437 247.596c9.169 2.912 14.241 12.706 11.329 21.875-2.912 9.169-12.706 14.241-21.875 11.329-9.169-2.912-14.241-12.706-11.329-21.875 2.912-9.169 12.705-14.241 21.875-11.329 0 0 9.169 2.912 0 0Z"
      fill="#FAC91C"
    />
    <path
      d="m172.68 294.958-33.402 47.32c-4.759 6.734-13.469 9.338-21.191 6.285-7.362-2.873-11.852-10.146-11.223-17.958l.538-7.543c3.412-.089 6.824-.987 9.787-2.783.539-.359 1.078-.629 1.617-.988 4.399-3.142 7.273-7.811 8.081-13.109.269-1.796-.988-3.502-2.784-3.861-1.796-.269-3.502.988-3.861 2.783-.538 3.592-2.514 6.645-5.387 8.8-2.873 2.065-6.465 2.963-10.057 2.334-7.362-1.167-12.3-8.081-11.134-15.443.27-1.796-.987-3.502-2.783-3.861-1.796-.27-3.502.987-3.861 2.783-1.616 9.967 4.4 19.305 13.648 22.358l-.629 8.081c-.808 10.775 5.388 20.652 15.444 24.692 7.273 2.874 15.175 2.065 21.55-1.706 2.963-1.706 5.567-4.13 7.632-7.003l33.402-47.32c14.636-20.652 38.43-32.235 63.751-30.798a3.323 3.323 0 0 0 3.502-3.143 3.187 3.187 0 0 0-3.053-3.501c-27.565-1.527-53.604 11.044-69.587 33.581Z"
      fill="#65B7E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M263.817 264.234c.012 6.446-5.204 11.682-11.651 11.694-6.447.011-11.682-5.205-11.694-11.652-.012-6.446 5.205-11.682 11.651-11.694 6.447-.012 11.683 5.205 11.694 11.652 0 0 .012 6.446 0 0Z"
      fill="#FFD18C"
    />
    <path
      d="M447.438 265.326s-24.154 24.962-42.561 34.121c-18.407 9.158-30.888 9.518-30.888 9.518l-59.531-17.599c0-.09 42.471-8.441 76.142-38.7l56.838 12.66Z"
      fill="#FAC91C"
    />
    <path
      d="M458.244 254.887s-25.411 23.166-44.805 30.977c-19.395 7.902-32.415 7.363-32.415 7.363l-62.224-21.37c0 .09 44.536-5.387 80.003-33.222l59.441 16.252Z"
      fill="#ED2B2B"
    />
    <path
      d="m284.918 73.719 27.207 10.775c6.105 2.424 9.069 9.338 6.644 15.444-2.424 6.105-9.338 9.068-15.444 6.644l-27.206-10.775c-6.106-2.514-9.069-9.428-6.645-15.444 2.425-6.105 9.338-9.069 15.444-6.644Z"
      fill="#FF3A3A"
    />
    <path
      d="m312.035 84.406-17.868-7.094c6.106 2.425 9.069 9.339 6.644 15.444-2.424 6.106-9.338 9.069-15.444 6.645l17.869 7.093c6.105 2.425 13.019-.538 15.444-6.644 2.424-6.106-.539-13.02-6.645-15.444Z"
      fill="#FAC91C"
    />
    <path
      d="m28.476 228.88 20.832 31.965c4.669 7.183 2.693 16.791-4.49 21.46-7.183 4.669-16.79 2.694-21.46-4.49L2.527 245.76c-4.67-7.093-2.694-16.701 4.49-21.37 7.183-4.669 16.79-2.694 21.46 4.49Z"
      fill="#FF3A3A"
    />
    <path
      d="M49.308 260.753 35.66 239.832c4.669 7.183 2.693 16.791-4.49 21.46-7.183 4.669-16.79 2.694-21.46-4.49l13.648 20.921c4.67 7.184 14.277 9.159 21.46 4.49 7.184-4.669 9.159-14.277 4.49-21.46Z"
      fill="#FAC91C"
    />
    <path
      opacity={0.2}
      d="m162.354 46.156-2.783-2.694 11.313-11.493 1.527 1.526c.718.629 1.796.629 2.424 0 .629-.718.629-1.795 0-2.424l-9.877-9.697c-.718-.629-1.796-.629-2.424 0-.629.718-.629 1.796 0 2.424l1.526 1.527-11.313 11.493-2.784-2.694-.449-.449c-.718-.628-1.796-.628-2.424 0-.629.718-.629 1.796 0 2.424l.449.45-33.492 34.12a2.882 2.882 0 0 0-.539 3.322l2.694 5.208-21.64 22.088c-.448.449-.448 1.078 0 1.527.45.449 1.168.449 1.527 0l21.639-22 5.298 2.515c1.167.539 2.514.27 3.322-.539l33.492-34.21.449.45c.718.628 1.796.628 2.424 0 .808-.72.809-1.797.09-2.425l-.449-.45Z"
      fill="url(#404_svg__b)"
    />
    <path
      opacity={0.2}
      d="M260.495 34.39c-1.167-1.796-1.616-4.04-1.347-6.195l.27-2.066h.269c.718.09 1.437-.359 1.526-1.167l.809-5.297c.089-.719-.36-1.437-1.168-1.527l-23.345-3.412c-.719-.09-1.437.36-1.527 1.167l-.808 5.298c-.09.718.359 1.437 1.168 1.527h.269l-.269 2.065c-.36 2.155-1.437 4.13-3.053 5.477l-3.233 2.783c-.987.809-1.616 1.976-1.796 3.323l-4.669 31.606c-.359 2.155 1.168 4.22 3.412 4.58l27.476 4.04c2.155.359 4.22-1.168 4.579-3.412l4.67-31.607c.089-1.167-.18-2.514-.898-3.591l-2.335-3.592Z"
      fill="url(#404_svg__c)"
    />
    <path
      opacity={0.2}
      d="M209.585 347.31c-.449.09-.898-.269-.988-.808l-1.257-12.571a.977.977 0 0 0-.988-.808l-11.403 1.167a.977.977 0 0 0-.808.988l1.257 12.571c.09.449-.27.898-.808.987l-12.571 1.257a.977.977 0 0 0-.808.988l1.167 11.403c.09.449.449.809.988.809l12.571-1.257c.448-.09.897.269.987.808l1.257 12.57c.09.449.449.808.988.808l11.403-1.167a.977.977 0 0 0 .808-.988l-1.257-12.57c-.089-.449.27-.898.809-.988l12.57-1.257a.977.977 0 0 0 .808-.988l-1.167-11.403c-.09-.539-.539-.898-.988-.808l-12.57 1.257Z"
      fill="url(#404_svg__d)"
    />
    <path
      opacity={0.2}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M286.858 315.482a7.633 7.633 0 1 1-7.96 13.024 7.633 7.633 0 0 1 7.96-13.024s3.597 2.197 0 0Z"
      fill="#ABC4EE"
    />
    <path
      opacity={0.2}
      d="m321.373 340.842 11.672-11.494c2.245-2.244 5.927-2.155 8.082.09 2.244 2.245 2.154 5.926-.09 8.081l-11.673 11.493c-2.155 2.155-5.836 2.155-8.081-.089-2.245-2.245-2.155-5.927.09-8.081Z"
      fill="#ABC4EE"
    />
    <defs>
      <linearGradient
        id="404_svg__a"
        x1={-30}
        y1={0}
        x2={-30}
        y2={515.804}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#74B973" />
        <stop offset={1} stopColor="#F3FBF2" />
      </linearGradient>
      <linearGradient
        id="404_svg__b"
        x1={111.307}
        y1={7.054}
        x2={63.667}
        y2={136.338}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#85C284" />
        <stop offset={1} stopColor="#85C284" stopOpacity={0.5} />
      </linearGradient>
      <linearGradient
        id="404_svg__c"
        x1={243.935}
        y1={-2.313}
        x2={195.076}
        y2={37.39}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#85C284" />
        <stop offset={1} stopColor="#85C284" stopOpacity={0.5} />
      </linearGradient>
      <linearGradient
        id="404_svg__d"
        x1={143.272}
        y1={333.123}
        x2={143.272}
        y2={452.1}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#85C284" />
        <stop offset={1} stopColor="#85C284" stopOpacity={0.01} />
      </linearGradient>
    </defs>
  </svg>
);
export default Svg404;
