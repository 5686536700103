import CountCard from "@app/components/CountCard";
// import { click } from "@testing-library/user-event/dist/click";
import classNames from "classnames";
import React from "react";

const InnerDashboardLayout = ({
  counts,
  children,
  preSection: PreSection,
  pageToShow,
  setPageToShow,
  clickableCards,
  isFetching,
}) => {
  return (
    <section className="flex flex-col overflow-y-auto relative h-full ">
      {counts && Array.isArray(counts) && (
        <div className="flex gap-1 overflow-hidden shrink-0 mb-1">
          {counts.map((item, index) => (
            <CountCard
              name={item?.name}
              value={item?.value}
              icon={item?.icon}
              className={classNames("flex-1", {
                "cursor-pointer": clickableCards,
                // "": item.id === pageToShow
              })}
              onClick={() => setPageToShow(item.id)}
              selected={clickableCards && item.id === pageToShow}
              isFetching={isFetching}
              key={`count_card_${index}`}
            />
          ))}
        </div>
      )}
      {PreSection && (
        <div className="md:w-[85%] mx-auto w-[400px]">
          <PreSection />
        </div>
      )}

      <section className="bg-[#ffffff]  p-5 overflow-y-auto flex-auto">
        {children}
      </section>
    </section>
  );
};

export default InnerDashboardLayout;
