import Button from "@app/components/common/Button";
// import Search from "@app/components/common/Search";
import { Plus } from "@app/components/Icon/icons";
import { SectionHeader } from "@app/components/section";
import Spinner from "@app/components/Spinner";
import TableBodyPlaceholder from "@app/components/TableBodyPlaceholder";
import { useErrorHandler } from "@app/utils/hooks";
import { useGenres } from "@app/utils/hooks/reactQuery/genre";
import { useNavigate } from "react-router-dom";
import GenresTable from "../components/GenresTable";

const tableHeader = [
  {
    name: "No",
    // fration: "oneth",
    width: " w-[34px]",
  },
  { name: "Name", fraction: "twoth" },
  { name: "Slug", fraction: "twoth" },
  { name: "Status", fraction: "twoth" },
  { name: "Edit Genre", fraction: "twoth" },
  // { name: "Delete Genre", fraction: "twoth", className: "text-primary-06" },
];

const GenresSection = () => {
  const navigate = useNavigate();

  const { data, isLoading } = useErrorHandler(useGenres);

  return (
    <>
      <div className="flex justify-between items-center w-full mb-6">
        {/* <Link to="add"> */}
        <Button
          icon={Plus}
          iconPosition="left"
          letterCase="capitalize"
          disabled={isLoading}
          onClick={() => {
            if (!isLoading) {
              navigate("add");
            }
          }}
        >
          Add Genre
        </Button>
        {/* </Link> */}
      </div>

      <SectionHeader title="Genres" total={data?.length} />
      {/* 
      <div className=" mt-5 mb-8">
        <Search handleInput={() => {}} />
      </div> */}

      <GenresTable
        tableHeader={tableHeader}
        tableBody={data}
        isLoading={isLoading}
        placeholder={TableBodyPlaceholder}
        spinner={<Spinner color="dark" show />}
      />
    </>
  );
};

export default GenresSection;
